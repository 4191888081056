import { useState, useEffect } from 'react';
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import MedLogo from '../assets/img/medicine.png';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import FollowUp from '../components/FollowUp';
// import PatientDobb from '../components/PatientDob';
import PrescriptionDx from '../components/PrescriptionDx';
import moment from 'moment';
import { useAuth } from '../context/AuthContext';
import MedicineList from '../components/MedicineList';
import CreatePreview from '../components/CreatePreview';
import AdviceList from '../components/AdviceList';

import { occupations } from '../assets/Utils/occupationList/occupationList';
import { thanasByDistrict } from '../assets/Utils/geodata/thanasByDistrict';
import CreatePreviewNew from '../components/CreatePreviewNew';

const Create = () => {
  const { currentUser, logout } = useAuth();
  const history = useHistory();
  const distList = Object.keys(thanasByDistrict).sort()
  // const [allDistrict] = useState([
  //   'Bagerhat',
  //   'Bandarban',
  //   'Barguna',
  //   'Barisal',
  //   'Bhola',
  //   'Bogra',
  //   'Brahmanbaria',
  //   'Chandpur',
  //   'Chapainawabganj',
  //   'Chittagong',
  //   'Chuadanga',
  //   'Comilla',
  //   "Cox's Bazar",
  //   'Dhaka',
  //   'Dinajpur',
  //   'Faridpur',
  //   'Feni',
  //   'Gaibandha',
  //   'Gazipur',
  //   'Gopalganj',
  //   'Habiganj',
  //   'Jamalpur',
  //   'Jessore',
  //   'Jhalokati',
  //   'Jhenaidah',
  //   'Joypurhat',
  //   'Khagrachhari',
  //   'Khulna',
  //   'Kishoreganj',
  //   'Kurigram',
  //   'Kushtia',
  //   'Lakshmipur',
  //   'Lalmonirhat',
  //   'Madaripur',
  //   'Magura',
  //   'Manikganj',
  //   'Meherpur',
  //   'Moulvibazar',
  //   'Munshiganj',
  //   'Mymensingh',
  //   'Naogaon',
  //   'Narail',
  //   'Narayanganj',
  //   'Narsingdi',
  //   'Natore',
  //   'Netrakona',
  //   'Nilphamari',
  //   'Noakhali',
  //   'Pabna',
  //   'Panchagarh',
  //   'Patuakhali',
  //   'Pirojpur',
  //   'Rajbari',
  //   'Rajshahi',
  //   'Rangamati',
  //   'Rangpur',
  //   'Satkhira',
  //   'Shariatpur',
  //   'Sherpur',
  //   'Sirajganj',
  //   'Sunamganj',
  //   'Sylhet',
  //   'Tangail',
  //   'Thakurgaon',
  // ]);

  const [isLoading, setIsLoaging] = useState(false);
  const [prescriptionId, setPrescriptionId] = useState(0);
  //
  const [mobileShow, setMobileShow] = useState(false);

  const [medModalShow, setMedModalShow] = useState(false);
  const handleMedModalClose = () => setMedModalShow(false);
  const handleMedModalShow = () => setMedModalShow(true);
  const handleNumberModalClose = () => history.push('/');
  const [patientType, setPatientType] = useState('old');

  const [patientToken, setPatientToken] = useState('1');
  const [patientId, setPatientId] = useState('');
  const [patientMobile, setPatientMobile] = useState('');
  const [patient, setPatient] = useState('');

  const [patientName, setPatientName] = useState('');
  const [patientPhone, setPatientPhone] = useState('');

  // const [patientDob, setPatientDob] = useState('');
  const [PatientAge, setPatientAge] = useState('');
  const [PatientAgeType, setPatientAgeType] = useState('years');

  const [patientGender, setPatientGender] = useState('');
  const [patientMaritalStatus, setPatientMaritalStatus] = useState('');
  const [patientOccupation, setPatientOccupation] = useState('');
  const [patientDistrict, setPatientDistrict] = useState('');
  const [patientThana, setPatientThana] = useState('');

  const [symptoms, setSymptoms] = useState('');
  const [signs, setSigns] = useState('');
  const [dx, setDx] = useState([]);
  const [investigations, setInvestigations] = useState('');
  // const [advice, setAdvice] = useState('');
  const [advices, setAdvices] = useState([]);
  const [adviceItem, setAdviceItem] = useState([]);
  const [followupDate, setFollowupDate] = useState(null);

  const [MedicineListItems, setMedicineListItems] = useState(null)
  const [medications, setMedications] = useState([]);
  // const [mediType, setMediType] = useState('Tablet/Capsule');
  const [mediType, setMediType] = useState('Tablet');
  // const [mediTypeTab, setMediTypeTab] = useState('Tablet');
  // const [mediTypeCap, setMediTypeCap] = useState('Capsule');
  const [mediName, setMediName] = useState('');
  const [mediId, setMediId] = useState('');
  const [mediMoring, setMediMoring] = useState('0');
  const [mediAfternoon, setMediAfternoon] = useState('0');
  const [mediEvening, setMediEvening] = useState('0');
  // const [beforeEvening, setBeforeEvening] = useState('0');
  // const [night, setNight] = useState('0');
  const [mediWithMeal, setMediWithMeal] = useState('After Meal');
  const [mediDays, setMediDays] = useState('');
  const [mediAdvice, setMediAdvice] = useState('');
  const [mediAdvices, setMediAdvices] = useState([]);
  // const [mediGenetic, setMediGenetic] = useState('');
  const [mediStrength, setMediStrength] = useState('');
  const [mediMixture, setMediMixture] = useState('');
  // const [mediDosePuffTime, setMediDosePuffTime] = useState('');
  const [mediDosesPuffTime, setMediDosesPuffTime] = useState('');

  const [mealChoice, setMealChoice] = useState(true);
  const [nebulization, setNebulization] = useState(false);
  const [tablet, setTablet] = useState(true);
  // const [medicationType, setMedicationType] = useState('Tablet/Capsule');
  const [medicationType, setMedicationType] = useState('Tablet');
  // const [medicationTypeTab, setMedicationTypeTab] = useState('Tablet');
  // const [medicationTypeCap, setMedicationTypeCap] = useState('Capsule');
  const [medicationPlaceholder, setMedicationPlaceholder] = useState(
    'Number of Drops (e.g. 2 drops per nostril/eye etc.)'
  );


  const fetchMedicine = async () => {
    const respPromise = await fetch(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=20`, { method: 'GET' })
      .catch((error) => {
        console.log(error);
        // if (error.response && error.response.status === 401) {
        //   logout();
        // }
      });
      
      const resp = await respPromise.json()
      const newArray = resp.results.map((medicine) => {
        return {
          ...medicine,
          value: medicine.name,
          label: `${medicine.name} — ${medicine.generic} — ${medicine.strength} — ${medicine.form}`,
        };
      });
      setMedicineListItems(newArray);
  }

  useEffect(() => {
    fetchMedicine();
  }, [])


  const idGen = (id) => {
    if (id < 10) {
      return '0000' + id;
    } else if (id < 100) {
      return '000' + id;
    } else if (id < 1000) {
      return '00' + id;
    } else if (id < 10000) {
      return '0' + id;
    } else {
      return id;
    }
  };

  const handleNewPatient = () => {
    setPatientType('new');
    setPatientMobile('');
  };
  const handleOldPatient = () => {
    setPatientType('old');
    setPatientMobile('');
  };


  const handleOldPatientSubmit = (e) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(patientMobile)) {
      toast.error('Please enter a valid mobile number!', {
        autoClose: 3000,
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient_by_confirm/${patientMobile}`
        )
        .then((resp) => {
          // console.log(resp.data);
          setPatientToken(resp.data.p_id);
          if (resp.data.status === 1) {
            setPatientId(resp.data.data.id);
            setPatient(resp.data.data);
            setPatientName(resp.data.data.name);
            setPatientPhone(resp.data.data.mobile);
            // setPatientDob(resp.data.data.dob);
            setPatientAge(resp.data.data.age + '');
            setPatientAgeType(resp.data.data.age_type + 's');
            setPatientGender(resp.data.data.gender);
            setPatientMaritalStatus(resp.data.data.marital_status);
            setPatientOccupation(resp.data.data.occupation);
            setPatientDistrict(resp.data.data.address_district);
            setPatientThana(resp.data.data.address_thana);
            setMobileShow(false);
          } else {
            toast.error(
              'Sorry! no patient found with this mobile number. Please create a new patient.',
              {
                autoClose: 3000,
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            logout();
          }
        });
    }
  };

  const handleNewPatientSubmit = (e) => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;
    if (!numberPattern.test(patientMobile)) {
      toast.error('Please enter a valid mobile number!', {
        autoClose: 3000,
      });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient_by_confirm/${patientMobile}`
        )
        .then((resp) => {
          // console.log(resp.data);
          setPatientToken(resp.data.p_id);
          if (resp.data.status === 1) {
            toast.warning('A patient found with this mobile number.', {
              autoClose: 3000,
            });
            setPatientId(resp.data.data.id);
            setPatient(resp.data.data);
            setPatientName(resp.data.data.name);
            setPatientPhone(resp.data.data.mobile);
            // setPatientDob(resp.data.data.dob);
            setPatientAge(resp.data.data.age + '');
            setPatientAgeType(resp.data.data.age_type + 's');
            setPatientGender(resp.data.data.gender);
            setPatientMaritalStatus(resp.data.data.marital_status);
            setPatientOccupation(resp.data.data.occupation);
            setPatientDistrict(resp.data.data.address_district);
            setPatientThana(resp.data.data.address_thana);
            setMobileShow(false);
          } else {
            setPatientPhone(patientMobile);
            setMobileShow(false);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            logout();
          }
        });
    }
  };


  const handleMedicineSelect = (e) => {
    // console.log(e.target.value);
    setMedicationType(e.target.value);
    if (e.target.value === 'Drop') {
      setMediType('Drop');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Number of Drops (e.g. 2 drops per nostril/eye etc.)'
      );
    } else if (e.target.value === 'Fleet Enema') {
      setMediType('Fleet Enema');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder('Times');
    } else if (e.target.value === 'Inhaler') {
      setMediType('Inhaler');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder('Puffs (e.g. 2 puffs once daily.)');
    } else if (e.target.value === 'Injections') {
      setMediType('Injections');
      // setMealChoice(true);
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'e.g. 1 amp/vial 12 hourly, I/V, I/M, S/C at Things.'
      );
    } else if (e.target.value === 'Nasal Spray') {
      setMediType('Nasal Spray');
      setNebulization(false);
      setMealChoice(false);
      setTablet(false);
      setMedicationPlaceholder('e.g. Once spray per nose/both nose.');
    } else if (e.target.value === 'Nebulization') {
      setMediType('Nebulization');
      setNebulization(true);
      setMealChoice(false);
      setTablet(false);
      setMedicationPlaceholder('Number of times per days with period.');
    } else if (e.target.value === 'Powder') {
      setMediType('Powder');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Times per day (e.g. Once daily in affected area.)'
      );
    } 
    else if (e.target.value === 'Cream') {
      setMediType('Cream');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Times per day (e.g. Once daily in affected area.)'
      );
    }
    else if (e.target.value === 'Ointments') {
      setMediType('Ointments');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Times per day (e.g. Once daily in affected area.)'
      );
    }else if (e.target.value === 'Suppository') {
      setMediType('Suppository');
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Does with times (e.g. 1 stick per revtal, once daily.)'
      );
    } else if (e.target.value === 'Syrup') {
      setMediType('Syrup');
      setMealChoice(true);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder('Does with times (e.g. 2.5 tsf 3 times daily.)');
    } else if (e.target.value === 'Tablet') {
      setMediType('Tablet');
      setMealChoice(true);
      setNebulization(false);
      setTablet(true);
    }else if (e.target.value === 'Capsule') {
      setMediType('Capsule');
      setMealChoice(true);
      setNebulization(false);
      setTablet(true);
    }else {
      setMealChoice(false);
      setNebulization(false);
      setTablet(false);
      setMedicationPlaceholder(
        'Number of Drops (e.g. 2 drops per nostril/eye etc.)'
      );
    }
  };

  const deleteMedication = (index) => {
    // console.log(index);
    setMedications(
      medications.filter((medication) => medication.medication_name !== index)
    );
  };

  const handleDeleteMedication = (medication_name) => {
    // let v = confirm("Are you sure to remove this?");
    if (window.confirm("Are you sure to remove this?")) {
      deleteMedication(medication_name)
    }
  }

  const handleFollowup = (Followup) => {
    const split = Followup.split('-');
    if (split[0].trim().length === 2 && split[2].trim().length === 4) {
      return split[2] + '-' + split[1] + '-' + split[0];
    } else if (split[0].trim().length === 4 && split[2].trim().length === 2) {
      return Followup;
    }
  }

  const handleDistrictSelection = (value) => {
    setPatientDistrict(value);
    setPatientThana('');
    // setThanaList(thanasByDistrict[dist].map(item => ({ label: item, value: item })));
  }

  const handleMedSubmit = (e) => {
    e.preventDefault();
    if (mediName === '') {
      toast.error('Please enter medicine name.', {
        autoClose: 3000,
      });
    }
    //  else if (mediGenetic === '') {
    //   toast.error('Please enter medicine genetic.', {
    //     autoClose: 3000,
    //   });
    // }
     else if (mediType !== 'Fleet Enema' && mediStrength === '') {
      toast.error('Please enter medicine strength.', {
        autoClose: 3000,
      });
    }
     else if (mediType !== 'Tablet' && mediType !== 'Capsule'  && mediDosesPuffTime === '') {
      toast.error(`Please enter ${medicationPlaceholder}.`, {
        autoClose: 3000,
      });
    } 
     
    else if (mediDays === '') {
      toast.error('Please enter days.', {
        autoClose: 3000,
      });
    }
    // else if (mediAdvice === '') {
    //   toast.error('Please enter medicine advice.', {
    //     autoClose: 3000,
    //   });
    // } 
    else {
      // console.log(mediAdvices);
      let fullMedications = {
        medication_type: mediType,
        medication_name: mediName,
        medicine: mediId ? mediId : '',
        medication_schedule_monring: mediMoring,
        medication_schedule_afternoon: mediAfternoon,
        medication_schedule_evening: mediEvening,
        // medication_schedule_before_evening: beforeEvening,
        // medication_schedule_night: night,
        medication_with_meal: mediWithMeal,
        days: mediDays,
        advice: mediAdvice,
        // advice: mediAdvices.map((medadv) => medadv.value).join(', '),
        // genetic: mediGenetic,
        strength: mediStrength,
        dose_drops_time_puff: mediDosesPuffTime,
        mixture_details: mediMixture,
      };
      // console.log(fullMedications);
      setMedications([...medications, fullMedications]);
      setMediName('');
      setMediMoring('0');
      setMediAfternoon('0');
      setMediEvening('0');
      // setBeforeEvening('0');
      // setNight('0');
      setMediDays('');
      setMediAdvice('');
      setMediAdvices([]);
      // setMediGenetic('');
      setMediStrength('');
      setMediMixture('');
      setMediDosesPuffTime('');
      handleMedModalClose();
    }
  };

  const handlePrescriptionSubmit = (e) => {
    e.preventDefault();
    if (patientPhone) {
      let numberPattern = /^01\d{9}$/;
      if (!patientPhone.match(/^(?:\+88|88)?(01[3-9]\d{8})$/gi)) {
        toast.error('Please enter a valid mobile number!', {
          autoClose: 3000,
        });
        return;
      }
    }


    if (patientName === '') {
      toast.error('Please enter patient name.', {
        autoClose: 3000,
      });
    }
    // else if (patientPhone === '') {
    //   toast.error('Please enter phone number.', {
    //     autoClose: 3000,
    //   });
    // }
    // else if (patientDob === '' || patientDob === null) {
    //   toast.error('Please enter patient date of birth.', {
    //     autoClose: 3000,
    //   });
    // } 
    else if (PatientAge === '' || PatientAge === null) {
      toast.error('Please enter patient age.', {
        autoClose: 3000,
      });
    }
    else if (PatientAgeType === '' || PatientAgeType === null) {
      toast.error('Please enter patient age type.', {
        autoClose: 3000,
      });
    }
    else if (patientGender === '') {
      toast.error('Please enter patient gender.', {
        autoClose: 3000,
      });
    }
    // else if (patientMaritalStatus === '') {
    //   toast.error('Please enter patient marital status.', {
    //     autoClose: 3000,
    //   });
    // }
    // else if (patientOccupation === '') {
    //   toast.error('Please enter patient occupation.', {
    //     autoClose: 3000,
    //   });
    // }
    else if (patientDistrict === '') {
      toast.error('Please enter patient district.', {
        autoClose: 3000,
      });
    }
    // else if (patientThana === '') {
    //   toast.error('Please enter patient address.', {
    //     autoClose: 3000,
    //   });
    // }
    else if (symptoms === '') {
      toast.error('Please enter symptoms.', {
        autoClose: 3000,
      });
    }
    // else if (signs === '') {
    //   toast.error('Please enter signs.', {
    //     autoClose: 3000,
    //   });
    // } 
    // else if (dx.length === 0) {
    //   toast.error('Please enter dx.', {
    //     autoClose: 3000,
    //   });
    // } 
    // else if (investigations === '') {
    //   toast.error('Please enter investigations.', {
    //     autoClose: 3000,
    //   });
    // } 
    // else if (advice === '') {
    //   toast.error('Please enter advice.', {
    //     autoClose: 3000,
    //   });
    // } 
    // else if (followupDate === '' || followupDate === null) {
    //   toast.error('Please enter followup date.', {
    //     autoClose: 3000,
    //   });
    // } 
    else {
      setIsLoaging(true);
      // console.log(patientId)

      const newDx = dx.map((dx) => dx.value);
      const dxToString = newDx.join(', ');
      const postPrescription = (ptnId) => {
        return {
          token: Math.floor(Math.random() * 100000 + 10),
          doctor: currentUser.doctor_id,
          patient: ptnId,
          dx: dxToString,
          symptoms: symptoms,
          signs: signs,
          investigations: investigations,
          // advice: advice,
          advice: advices.map((adv) => adv.value).join(', '),
          followup_date: moment(followupDate).format('YYYY-MM-DD'),
        };
      };

      const postMedications = (presId) => {
        return medications.map((medication) => {
          return {
            ...medication,
            prescription: presId,
            doctor: currentUser.doctor_id,
          };
        });
      };

      if (!patientId) {
        let postPatient = {
          token: patientToken ? patientToken : 1,
          doctor: currentUser.doctor_id,
          name: patientName,
          gender: patientGender,
          marital_status: patientMaritalStatus,
          occupation: patientOccupation,
          mobile: patientPhone,
          // dob: moment(patientDob).format('YYYY-MM-DD'),
          age: parseInt(PatientAge),
          age_type: PatientAgeType.slice(0, -1),
          address_district: patientDistrict,
          address_thana: patientThana,
        };

        var formBody = [];
        for (var key in postPatient) {
          var encodedKey = encodeURIComponent(key);
          var encodedValue = encodeURIComponent(postPatient[key]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');

        const token = JSON.parse(localStorage.getItem('user-info')).token

        fetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/`, {
          method: 'POST',
          body: formBody,
          headers: {
            'Authorization': 'Token ' + token,
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        })
          .then((response) => response.json())
          .then((response) => {
            // console.log(response);
            // setPatientId(response.data.id);
            const patient_id = response.id;
            // console.log(postPrescription(patient_id));
            axios
              .post(
                `${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/`,
                postPrescription(patient_id)
              )
              .then((response) => {
                // console.log(response.data.id);
                const prescriptionId = response.data.id;
                setPrescriptionId(prescriptionId);

                if (medications.length) {
                  axios
                    .post(
                      `${process.env.REACT_APP_AXIOS_BASE_URL}/api/medication_entry/`,
                      postMedications(prescriptionId)
                    )
                    .then((resp) => {
                      // console.log(resp.data);
                      toast.success('Prescription Created Successfully.', {
                        autoClose: 2000,
                      });
                      setIsLoaging(false);
                      setShowShareModal(true);
                      // history.push('/');
                    })
                    .catch((error) => {
                      console.log(error);
                      if (error.response && error.response.status === 401) {
                        logout();
                      }
                    });
                } else {
                  toast.success('Prescription Created Successfully.', {
                    autoClose: 2000,
                  });
                  setIsLoaging(false);
                  setShowShareModal(true)
                  // history.push('/');
                }
              })
              .catch((err) => {
                console.log(err);
                toast.error(
                  'Sorry, something went wrong. Please try again later.',
                  {
                    autoClose: 2000,
                  }
                );
                if (err.response && err.response.status === 401) {
                  logout();
                }
                setIsLoaging(false);
              });
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              'Sorry, something went wrong. Please try again later.',
              {
                autoClose: 2000,
              }
            );
            setIsLoaging(false);
            if (err.response && err.response.status === 401) {
              logout();
            }
          });

        // console.log(postPatient);
      } else {
        // console.log(postPrescription);
        axios
          .post(
            `${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/`,
            postPrescription(patientId)
          )
          .then((response) => {
            // console.log(response.data);
            const prescriptionId = response.data.id;
            setPrescriptionId(prescriptionId);

            if (medications.length) {
              axios
                .post(
                  `${process.env.REACT_APP_AXIOS_BASE_URL}/api/medication_entry/`,
                  postMedications(prescriptionId)
                )
                .then((resp) => {
                  // console.log(resp.data);
                  toast.success('Prescription Created Successfully.', {
                    autoClose: 2000,
                  });
                  setIsLoaging(false);
                  setShowShareModal(true)
                  // history.push('/');
                })
                .catch((error) => {
                  console.log(error);
                  if (error.response && error.response.status === 401) {
                    logout();
                  }
                });
            } else {
              toast.success('Prescription Created Successfully.', {
                autoClose: 2000,
              });
              setIsLoaging(false);
              setShowShareModal(true)
              // history.push('/');
            }
          })
          .catch((err) => {
            console.log(err.data);
            toast.error(
              'Sorry, something went wrong. Please try again later.',
              {
                autoClose: 2000,
              }
            );
            if (err.response && err.response.status === 401) {
              logout();
            }
            setIsLoaging(false);
          });
        // console.log(medications);
      }
    }
  };



  const [ShowShareModal, setShowShareModal] = useState(false);
  const [IsCopyingTesxt, setIsCopyingTesxt] = useState(false);

  const copyRxText = async () => {
    setIsCopyingTesxt(true);

    const user_info = JSON.parse(localStorage.getItem('user-info'));
    const token = user_info.token;
    const user_id = user_info.user_id;

    const doctorResp = await fetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor_by_user/${user_id}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Token ' + token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    const doctor = await doctorResp.json()

    // console.log(medicationResult);

    let text = `${moment().format('DD-MM-YYYY')}\n\
${doctor.title} ${doctor.full_name}\n\
${doctor.bio ? doctor.bio + '\n' : ''}\
BMDC Reg. No. : ${doctor.bmdc_number}\n\
Address: ${doctor.address_thana ? doctor.address_thana + ', ' : ''}${doctor.address_district}\n\
\n\
Patient Name: ${patientName}\n\
Mobile: ${patientMobile}\n\
Age : ${PatientAge} ${PatientAgeType}\n\
Sex: ${patientGender}\n\
Investigation: ${dx.map((dx) => dx.value).join(',  ')}\n\
CC : ${symptoms} \n\
\n\
Rx \n\
${medications.map((item, i) => `${i + 1}. ${item.medication_type} ${item.medication_name} ${item.strength} \n\
${item.medication_schedule_monring}+${item.medication_schedule_afternoon}+${item.medication_schedule_evening}		---${item.days}: Days \n\
${item.advice ? item.advice : ''}
\n` ).join('')}\
Advice: ${advices.map((adv) => adv.value).join(', ')}\n\
\n\
Next Appointment: ${followupDate ? moment(followupDate).format('DD MMM YYYY') : ''}\n\
${doctor.mobile ? ('Call For Appointment: ' + doctor.mobile) : ('Email For Appointment: ' + doctor.user.username)}\n\
\n\
Created with e-laj by Amarlab Ltd.\n\
${moment().format('DD MMM YYYY; hh:mm A')}`;

    await navigator.clipboard.writeText(text);
    setIsCopyingTesxt(false)
    toast.success("Prescription texts has been copied, you can paste it anywhere ", { autoClose: 2000 });

  }

  const copyUrl = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}/prescription/${prescriptionId}`);
    toast.success("Prescription URL has been copied, you can paste it anywhere ", { autoClose: 2000 });
  }

  return (
    <div className='row homepage create'>
      <div className='col-md-7'>
        <div className='card'>
          <div className='card-body'>
            <h2 className='mb-4'>
              Patient ID: #{patientToken ? idGen(patientToken) : '00000'}
            </h2>
            <div className='border border-1 border-secondary rounded px-6 py-4 form-group' style={{ backgroundColor: '#54b9a81a' }}>
              <div><span className='font-weight-bolder'>Note:</span> If you insert patient mobile number, they can avail digital rx through sms.</div>
            </div>
            <form onSubmit={handlePrescriptionSubmit}>
              <div className='form-group'>
                <input
                  type='number'
                  className='form-control form-control-lg'
                  placeholder='Mobile Number (We will send prescription URL)'
                  value={patientPhone ? patientPhone : ''}
                  onChange={(e) => setPatientPhone(e.target.value)}
                  disabled={patient.mobile}
                />
              </div>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Patient Name'
                  value={patientName ? patientName : ''}
                  onChange={(e) => setPatientName(e.target.value)}
                  disabled={patient.name}
                />
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group' style={{ display: 'flex' }}>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='Age'
                      value={PatientAge ? PatientAge : ''}
                      onChange={(e) => setPatientAge(e.target.value)}
                      disabled={patient.age}
                    />
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.age_type}
                      onChange={(e) => setPatientAgeType(e.target.value)}
                      value={PatientAgeType ? PatientAgeType : 'years'}
                    >
                      <option value='days'>Days</option>
                      <option value='months'>Months</option>
                      <option value='years'>Years</option>
                    </select>
                    {/* <PatientDobb
                      date={patientDob}
                      setDate={setPatientDob}
                      patient={patient}
                    /> */}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.gender}
                      onChange={(e) => setPatientGender(e.target.value)}
                      value={patientGender ? patientGender : ''}
                    >
                      <option value=''>Gender</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Other'>Other</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.marital_status}
                      onChange={(e) => setPatientMaritalStatus(e.target.value)}
                      value={patientMaritalStatus}
                    >
                      <option value=''>Marital Status</option>
                      <option value='Single'>Single</option>
                      <option value='Married'>Married</option>
                      <option value='Widowed'>Widowed</option>
                      <option value='Divorced'>Divorced</option>
                      <option value='Separated'>Separated</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    {/* <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='Occupation'
                      value={patientOccupation ? patientOccupation : ''}
                      onChange={(e) => setPatientOccupation(e.target.value)}
                      disabled={patient.occupation}
                    /> */}
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.occupation}
                      onChange={(e) => setPatientOccupation(e.target.value)}
                      value={patientOccupation}
                    >
                      <option value=''>Occupation</option>
                      {occupations &&
                        occupations.map((occupation, index) => (
                          <option value={occupation} key={index}>
                            {occupation}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <h5 className='font-weight-normal'>Address:</h5>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    {/* <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='Enter address'
                      value={patientThana ? patientThana : ''}
                      onChange={(e) => setPatientThana(e.target.value)}
                      disabled={patient.address_thana}
                    /> */}
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.address_thana}
                      onChange={(e) => setPatientThana(e.target.value)}
                      value={patientThana}
                    >
                      <option value=''>Thana</option>
                      {thanasByDistrict[patientDistrict] &&
                        thanasByDistrict[patientDistrict].map((thana, index) => (
                          <option value={thana} key={index}>
                            {thana}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg meal_select'
                      disabled={patient.address_district}
                      onChange={(e) => handleDistrictSelection(e.target.value)}
                      value={patientDistrict}
                    >
                      <option value=''>District</option>
                      {distList &&
                        distList.map((district, index) => (
                          <option value={district} key={index}>
                            {district}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <textarea
                  rows={3}
                  className='form-control form-control-lg'
                  placeholder='C/C'
                  value={symptoms ? symptoms : ''}
                  onChange={(e) => setSymptoms(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <textarea
                  rows={3}
                  className='form-control form-control-lg'
                  placeholder='Relevant History'
                  value={signs ? signs : ''}
                  onChange={(e) => setSigns(e.target.value)}
                />
              </div>
              <div className='form-group'>
                <textarea
                  rows={3}
                  className='form-control form-control-lg'
                  placeholder='O/E'
                  value={investigations ? investigations : ''}
                  onChange={(e) => setInvestigations(e.target.value)}
                />
              </div>
              <div className='form-group'>
                {/* <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Dx. Blood Test MRI'
                /> */}
                <PrescriptionDx setDx={setDx} />
              </div>
              {!medications.length && (
                <div className='form-group add_medication'>
                  <h6 className='mb-0'>Add Medication</h6>
                  <button
                    type='button'
                    className='btn remove_bg'
                    onClick={handleMedModalShow}
                  >
                    <i style={{ color: '#fff' }} className='flaticon2-add-1 m-0 p-0' />
                  </button>
                </div>
              )}
              {medications.length !== 0 && (
                <div>
                  {medications.map((medication, index) => (
                    <div className='form-group' key={index}>
                      <div className='card card-custom card-stretch gutter-b medicine_bg'>
                        <div className='card-body p-6'>
                          <div className='d-flex flex-wrap align-items-center medicine'>
                            <div className='icons mr-6 tablet_img_bg'>
                              <img
                                src={MedLogo}
                                height={65}
                                alt='Medicine logo'
                              />
                            </div>
                            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                              <div className='d-flex align-items-start justify-content-between'>
                                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                                  <span className='text-dark-65 font-size-lg mb-1'>
                                    <span className='mr-3 text-dark-75 font-weight-bolder'>
                                      {medication.medication_name}{' '}
                                      {medication.strength},
                                    </span>{' '}
                                    {medication.genetic}
                                  </span>
                                  {(medication.medication_type === 'Tablet' || medication.medication_type === 'Syrup') && <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                    {medication.medication_type === 'Tablet' &&
                                      `${medication.medication_schedule_monring}+${medication.medication_schedule_afternoon}+${medication.medication_schedule_evening} `}
                                    {medication.medication_with_meal}
                                  </span>}
                                  {(medication.medication_type !== 'Tablet' && medication.medication_type !== 'Tablet') && <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                    {medication.dose_drops_time_puff}
                                  </span>}
                                     {(medication.medication_type === 'Capsule' || medication.medication_type === 'Syrup') && <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                    {medication.medication_type === 'Capsule' &&
                                      `${medication.medication_schedule_monring}+${medication.medication_schedule_afternoon}+${medication.medication_schedule_evening} `}
                                    {medication.medication_with_meal}
                                  </span>}
                                  {medication.medication_type !== 'Nebulization' && <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                    {medication.days} Days
                                  </span>}
                                  {medication.medication_type === 'Nebulization' && <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                    {medication.mixture_details}
                                  </span>}
                                </div>
                                <span
                                  onClick={() => handleDeleteMedication(medication.medication_name)}
                                  className='pointer'
                                >
                                  <i style={{ color: '#54b9a8', backgroundColor: '#54b9a820' }} className='la la-trash-alt trash' />
                                </span>
                              </div>
                              <div className='d-flex justify-content-between align-items-center'>
                                <span className='text-dark-65 font-weight-bold font-size-sm my-1'>
                                  <span className='mr-1 text-dark-75 font-weight-bolder'>
                                    Advice:
                                  </span>{' '}
                                  {medication.advice}
                                </span>
                                {/* <div
                                  className='left_right_icons d-flex flex-column up_down'
                                  style={{ marginRight: '4.5px' }}
                                >
                                  <i style={{color: '#54b9a8', backgroundColor: '#54b9a820'}} className='ki ki-arrow-up' />
                                  <i style={{color: '#54b9a8', backgroundColor: '#54b9a820'}} className='ki ki-arrow-down' />
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {medications.length !== 0 && (
                <div className='form-group'>
                  <p className='text-center mb-0'>
                    <button
                      type='button'
                      className='btn remove_bg btn-sm'
                      onClick={handleMedModalShow}
                    >
                      Add Another
                    </button>
                  </p>
                </div>
              )}
              <div className='form-group'>
                {/* <textarea
                  rows={3}
                  className='form-control form-control-lg'
                  placeholder='Advice'
                  value={advice ? advice : ''}
                  onChange={(e) => setAdvice(e.target.value)}
                /> */}
                <AdviceList setMainAdvice={setAdviceItem} setAdvice={setAdvices} />
              </div>
              <div className='form-group'>
                <FollowUp date={followupDate} setDate={setFollowupDate} />
              </div>
              <div className='form-group float-right'>
                {isLoading ? (
                  <button
                    type='submit'
                    className='btn btn-primary btn-lg'
                    disabled
                    style={{
                      cursor: 'initial',
                    }}
                  >
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='mr-2'
                      style={{
                        width: '15px',
                        height: '15px',
                      }}
                    />
                    Create and Send Rx.
                  </button>
                ) : (
                  <button type='submit' className='btn remove_bg btn-lg'>
                    Create and Send Rx.
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='col-md-5' >
        <div className='sticky-top' style={{ overflow: 'auto' }}>
          {/* <CreatePreview
            patientName={patientName}
            // patientDob={moment(patientDob).format('YYYY-MM-DD')}
            patientAge={PatientAge}
            patientAgeType={PatientAgeType}
            patientPhone={patientPhone}
            patientGender={patientGender}
            patientThana={patientThana}
            patientDistrict={patientDistrict}
            symptoms={symptoms}
            signs={signs}
            dx={dx}
            investigations={investigations}
            medications={medications}
            advice={advices.map(item=> item.label).join(', ')}
            followupDate={moment(followupDate).format('DD MMM YYYY')}
          /> */}
          <CreatePreviewNew
            patientName={patientName}
            // patientDob={moment(patientDob).format('YYYY-MM-DD')}
            patientAge={PatientAge}
            patientAgeType={PatientAgeType}
            patientPhone={patientPhone}
            patientGender={patientGender}
            patientThana={patientThana}
            patientDistrict={patientDistrict}
            symptoms={symptoms}
            signs={signs}
            dx={dx}
            investigations={investigations}
            medications={medications}
            advice={advices.map(item => item.label).join(', ')}
            followupDate={moment(followupDate).format('DD MMM YYYY')}
          />
        </div>
      </div>
      <Modal
        show={mobileShow}
        animation={false}
        className='phone_modal'
        onHide={handleNumberModalClose}
      >
        <Modal.Body>
          <h2 className='text-center'>Select Patient Type</h2>
          <Row className='py-7'>
            <Col md={6}>
              <Button
                className={`btn btn-block btn-lg btn-light-primary tab ${patientType === 'new' ? 'active' : ''
                  }`}
                onClick={handleNewPatient}
              >
                New Patient
              </Button>
            </Col>
            <Col md={6}>
              <Button
                className={`btn btn-block btn-lg btn-light-primary tab ${patientType === 'old' ? 'active' : ''
                  }`}
                onClick={handleOldPatient}
              >
                Old Patient
              </Button>
            </Col>
          </Row>
          <Row className='mt-0'>
            <Col>
              {patientType === 'old' ? (
                <form onSubmit={handleOldPatientSubmit}>
                  <div className='form-group'>
                    <input
                      type='number'
                      className='form-control form-control-lg'
                      placeholder='Enter Patient Mobile Number (e.g. 017xxxxxxxx)'
                      onChange={(e) => setPatientMobile(e.target.value)}
                      value={patientMobile}
                    />
                  </div>
                  <div className='form-group float-right mb-0'>
                    <button className='btn btn-secondary remove_bg_light mr-3'>
                      <Link to='/' style={{ color: '#54b9a8' }} >Cancle</Link>
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Confirm
                    </button>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleNewPatientSubmit}>
                  <div className='form-group'>
                    <input
                      type='number'
                      className='form-control form-control-lg'
                      placeholder='Enter Patient Mobile Number (e.g. 017xxxxxxxx)'
                      onChange={(e) => setPatientMobile(e.target.value)}
                      value={patientMobile}
                    />
                  </div>
                  <div className='form-group float-right mb-0'>
                    <button className='btn btn-secondary remove_bg_light mr-3'>
                      <Link to='/' style={{ color: '#54b9a8' }} >Cancle</Link>
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Confirm
                    </button>
                  </div>
                </form>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={medModalShow}
        onHide={handleMedModalClose}
        animation={false}
        size='lg'
      >
        <div className='modal-body medicine_modal'>
          <form onSubmit={handleMedSubmit}>
            <div className='form-group'>
              <select
                className='form-control form-control-lg'
                onChange={handleMedicineSelect}
                value={mediType}
              >
                <option value='Drop'>Drop</option>
                <option value='Fleet Enema'>Fleet Enema</option>
                <option value='Inhaler'>Inhaler</option>
                <option value='Injections'>Injections</option>
                <option value='Nasal Spray'>Nasal Spray</option>
                <option value='Nebulization'>Nebulization</option>
                {/* <option value='Powder, Cream & Ointments'>
                  Powder, Cream &amp; Ointments
                </option> */}
                <option value='Powder'>
                  Powder
                </option>
                <option value='Cream'>
                 Cream
                </option>
                <option value='Ointments'>
                  Ointments
                </option>
                <option value='Suppository'>Suppository</option>
                <option value='Syrup'>Syrup</option>
                {/* <option value='Tablet/Capsule'>Tablet/Capsule</option> */}
                <option value='Tablet'>Tablet</option>
                <option value='Capsule'>Capsule</option>
              </select>
            </div>
            <div className='form-group'>
              {/* <input
                type='text'
                className='form-control form-control-lg'
                placeholder='Medicine Name'
                value={mediName ? mediName : ''}
                onChange={(e) => setMediName(e.target.value)}
              /> */}
              <MedicineList
                setMedName={setMediName}
                setMedid={setMediId}
                setMediStrength={setMediStrength}
                // setMediGenetic={setMediGenetic}
                medListItems={MedicineListItems}
              />
            </div>
            <div className='row'>
              {/* <div
                className={`${medicationType !== 'Fleet Enema' ? 'col-md-6' : 'col-md-12'
                  }`}
              >
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    placeholder='Generic'
                    value={mediGenetic ? mediGenetic : ''}
                    onChange={(e) => setMediGenetic(e.target.value)}
                  />
                </div>
              </div> */}
              {medicationType !== 'Fleet Enema' && (
                <div className='col-md-12'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control form-control-lg'
                      placeholder='Strength (mg)'
                      value={mediStrength ? mediStrength : ''}
                      onChange={(e) => setMediStrength(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            {nebulization && (
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder='Mixture Details'
                  value={mediMixture ? mediMixture : ''}
                  onChange={(e) => setMediMixture(e.target.value)}
                />
              </div>
            )}
            {tablet && (
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg number_of_dose'
                      onChange={(e) => setMediMoring(e.target.value)}
                      value={mediMoring}
                    >
                      <option value='0'>00</option>
                      <option value='1'>01</option>
                      <option value='2'>02</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg number_of_dose'
                      onChange={(e) => setMediAfternoon(e.target.value)}
                      value={mediAfternoon}
                    >
                      <option value='0'>00</option>
                      <option value='1'>01</option>
                      <option value='2'>02</option>

                    </select>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg number_of_dose'
                      onChange={(e) => setMediEvening(e.target.value)}
                      value={mediEvening}
                    >
                      <option value='0'>00</option>
                      <option value='1'>01</option>
                      <option value='2'>02</option>
                    </select>
                  </div>
                </div>
                {/* <div className='col'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg number_of_dose'
                      onChange={(e) => setBeforeEvening(e.target.value)}
                      value={beforeEvening}
                    >
                      <option value='0'>00</option>
                      <option value='1'>01</option>
                      <option value='2'>02</option>
                    </select>
                  </div>
                </div> */}
                 {/* <div className='col'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg number_of_dose'
                      onChange={(e) => setNight(e.target.value)}
                      value={night}
                    >
                      <option value='0'>00</option>
                      <option value='1'>01</option>
                      <option value='2'>02</option>
                    </select>
                  </div>
                </div> */}
              </div>
            )}
            {/* {!tablet && (
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder={medicationPlaceholder}
                  value={mediDosePuffTime ? mediDosePuffTime : ''}
                  onChange={(e) => {
                    setMediDosePuffTime(e.target.value);
                    console.log(e.target.value);
                  }}
                />
              </div>
            )} */}
            {!tablet && (
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control form-control-lg'
                  placeholder={medicationPlaceholder}
                  value={mediDosesPuffTime}
                  onChange={(e) => {
                    setMediDosesPuffTime(e.target.value);
                    // console.log(e.target.value);
                  }}
                />
              </div>
            )}
            <div className='row'>
              {mealChoice && (
                <div className='col-md-6'>
                  <div className='form-group'>
                    <select
                      className='form-control form-control-lg meal_select'
                      onChange={(e) => setMediWithMeal(e.target.value)}
                      value={mediWithMeal}
                    >
                      <option value='Before Meal'>Before Meal</option>
                      <option value='After Meal'>After Meal</option>
                      {/* <option value='Inside Meal'>Inside Meal</option> */}
                    </select>
                  </div>
                </div>
              )}
              {!nebulization && (
                <div className={`${mealChoice ? 'col-md-6' : 'col-md-12'}`}>
                  <div className='form-group'>
                    <input
                      type='number'
                      className='form-control form-control-lg'
                      placeholder='Days'
                      value={mediDays ? mediDays : ''}
                      onChange={(e) => setMediDays(e.target.value)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='form-group'>
              <textarea
                rows={4}
                className='form-control form-control-lg'
                placeholder='Advice'
                value={mediAdvice ? mediAdvice : ''}
                onChange={(e) => {
                  setMediAdvice(e.target.value);
                }}
              />
              {/* <AdviceList advList={adviceItem} setAdvice={setMediAdvices} /> */}
            </div>
            <div className='form-group float-right mb-0'>
              <button
                className='btn btn-light mr-2 btn-lg'
                onClick={handleMedModalClose}
              >
                Cancle
              </button>
              <button
                type='submit'
                className='btn btn-primary btn-lg'
              // onClick={handleMedModalClose}
              >
                Add Now
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <>
        <Modal show={ShowShareModal} backdrop='static' /*onHide={() => setShowShareModal(false)}*/>
          <Modal.Header className='mx-6' style={{ padding: 0, margin: '1rem 1.25rem 0rem 1.25rem', position: 'relative' }} >
            <Modal.Title className='mb-2'>Share Your Rx.</Modal.Title>
            <div
              onClick={() => {
                setShowShareModal(false)
                setTimeout(() => {
                  history.push('/');
                }, 1);
              }}
              className="d-flex justify-content-center align-items-center"
              style={{ borderRadius: 15, width: '20px', height: '20px', color: '#7C8399C0', border: '1px solid #7C8399C0', fontSize: 18, cursor: 'pointer', position: 'absolute', right: '-5px', top: 0 }} >
              <span>&times;</span>
            </div>
          </Modal.Header>
          <Modal.Body className='m-2 d-flex flex-column'>
            <Button variant="info" className='mx-0 font-weight-normal h6 p-3 mb-3' onClick={copyRxText} >Copy Rx. in text
              {IsCopyingTesxt && <Spinner className='ml-3' animation="border" style={{height: '1.3rem', width: '1.3rem', borderWidth: '0.15em' }}/>}
            </Button>
            <Button variant="primary" className='mx-0 font-weight-normal h6 p-3' onClick={copyUrl} >Copy Rx. url</Button>
          </Modal.Body>

        </Modal>
      </>
    </div>
  );
};

export default Create;
