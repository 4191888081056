import Skeleton from 'react-loading-skeleton';

const PatientLoading = () => {
  return (
    <div className='col-12'>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card-body p-6'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-75 font-weight-bolder font-size-lg'>
                <Skeleton width={105} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                <Skeleton width={125} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                <Skeleton width={200} />
              </span>
            </div>
            <div className='d-flex align-self-start py-lg-0 py-2'>
              <div className='d-flex flex-column text-right'>
                <span className='text-primary font-weight-bolder font-size-h4'>
                  <Skeleton width={75} />
                </span>
              </div>
            </div>
          </div>
          <div className='d-flex flex-wrap align-items-end'>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-50 font-weight-bolder'>
                <Skeleton width={80} />
              </span>
            </div>
            <div className='symbol symbol-60 symbol-2by3 flex-shrink-0'>
              <span className='icons'>
                <Skeleton width={35} height={35} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientLoading;
