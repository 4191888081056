import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import 'react-datepicker/dist/react-datepicker.css';

const FollowUp = ({ date, setDate }) => {
  const years = range(getYear(new Date()), 2025, 1);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            type='button'
            className='btn btn-primary btn-sm mr-2'
            style={{
              borderRadius: '0',
              padding: '0 10px',
            }}
          >
            {'<'}
          </button>

          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
            className='mr-2'
            style={{
              outline: 'none',
              border: '1px solid #777',
              background: '#fff',
              padding: '2px 8px',
              fontSize: '13px',
            }}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
            style={{
              outline: 'none',
              border: '1px solid #777',
              background: '#fff',
              padding: '2px 5px',
              fontSize: '13px',
            }}
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            type='button'
            className='btn btn-primary btn-sm ml-2'
            style={{
              borderRadius: '0',
              padding: '0 10px',
            }}
          >
            {'>'}
          </button>
        </div>
      )}
      selected={date}
      onChange={(date) => {
        setDate(date);
        console.log(date);
      }}
      className='form-control form-control-lg'
      placeholderText='Select Next Followup (DD/MM/YYYY)'
      minDate={new Date()}
      dateFormat='dd-MM-yyyy'
    />
  );
};

export default FollowUp;
