import { useState } from 'react';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import Blank from '../assets/img/blank.png';
import { BsCheckCircle } from 'react-icons/bs';
import { specialities } from '../assets/Utils/specialityList/specialities';
import { thanasByDistrict } from '../assets/Utils/geodata/thanasByDistrict';

const Profile = () => {
  const { currentUser, updateProfile } = useAuth();
  const [fullName, setFullName] = useState(currentUser.doctor.full_name);
  const [phone, setPhone] = useState(currentUser.doctor.mobile_number);
  const [bmdc, setBmdc] = useState(currentUser.doctor.bmdc_number);
  const [bio, setBio] = useState(currentUser.doctor.bio);
  const [thana, setThana] = useState(currentUser.doctor.address_thana);
  const [district, setDistrict] = useState(currentUser.doctor.address_district);
  const [specialization, setSpecialization] = useState(
    currentUser.doctor.speaciality
  );
  const [title, setTitle] = useState(currentUser.doctor.title);
  const [profilePic, setProfilePic] = useState(null);
  const [visitingCard, setVisitingCard] = useState(null);

  const distList = Object.keys(thanasByDistrict).sort()

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submited');
    if (fullName === '' || fullName === null) {
      toast.error('Please enter your name!', {
        autoClose: 3000,
      });
    } else if (phone === '' || phone === null) {
      toast.error('Please enter phone number!', {
        autoClose: 3000,
      });
    } else if (bmdc === '' || bmdc === null) {
      toast.error('Please enter bmdc number!', {
        autoClose: 3000,
      });
    } else if (bio === '' || bio === null) {
      toast.error('Please enter bio!', {
        autoClose: 3000,
      });
    } else if (thana === '' || thana === null) {
      toast.error('Please enter address!', {
        autoClose: 3000,
      });
    } else if (district === '' || district === null) {
      toast.error('Please enter address!', {
        autoClose: 3000,
      });
    } else if (specialization === '' || specialization === null) {
      toast.error('Please enter your specialization!', {
        autoClose: 3000,
      });
    } else if (title === '' || title === null) {
      toast.error('Please enter title!', {
        autoClose: 3000,
      });
    } else if (profilePic && profilePic.size > 1024000) {
      toast.error(`Profile picture can't be exceed more than 1MB!`, {
        autoClose: 3000,
      });
    } else if (visitingCard && visitingCard.size > 1024000) {
      toast.error(`Visiting card can't be exceed more than 1MB!`, {
        autoClose: 3000,
      });
    } else {
      updateProfile(
        fullName,
        phone,
        bmdc,
        bio,
        thana,
        district,
        specialization,
        title,
        profilePic,
        visitingCard
      );
    }
  };

  const handleDistrictSelection = (value) => {
    setDistrict(value);
    setThana('');
  }

  return (
    <div className='row profile'>
      <div className='col-md-8'>
        <div className='card card-custom card-stretch'>
          <div className='card-header py-3'>
            <div className='card-title align-items-start flex-column'>
              <h3 className='card-label font-weight-bolder text-dark'>
                Personal Information
              </h3>
              <span className='text-muted font-weight-bold font-size-sm mt-1'>
                Update your personal informaiton
              </span>
            </div>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Profile Picture
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <div
                    className='image-input image-input-outline'
                    id='kt_profile_avatar'
                    style={
                      currentUser.doctor.profile_pic
                        ? {
                          backgroundImage: `url(${process.env.REACT_APP_AXIOS_BASE_URL}/${currentUser.doctor.profile_pic})`,
                        }
                        : { backgroundImage: `url(${Blank})` }
                    }
                  >
                    <div className='image-input-wrapper'></div>
                  </div>
                  <div></div>
                  <div className='custom-file'>
                    <input
                      type='file'
                      className='custom-file-input'
                      accept='image/x-png,image/gif,image/jpeg'
                      onChange={(e) => setProfilePic(e.target.files[0])}
                    />
                    <label className='custom-file-label'>
                      Change Profiel Picture
                    </label>
                  </div>
                  <div style={{ backgroundColor: '#cdc0', display: 'flex', marginTop: '6px' }}>
                    <div style={{ backgroundColor: bmdc ? '#6db959d0' : '#99020290', color: '#fff', fontWeight: 600, display: 'flex', alignItems: 'center', padding: '4px 25px', borderRadius: '25px' }}>
                      {bmdc && <BsCheckCircle size={15} style={{ marginRight: 5 }} />}
                      {bmdc ? `Verified Doctor` : 'Please put your BMDC number to verify your account'}
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Full Name
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    placeholder='enter your name'
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Phone number
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your phone'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Email address
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <input
                    type='email'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your email'
                    value={currentUser.email}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  BMDC number
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <input
                    // type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter bmdc number'
                    value={bmdc ? bmdc : ''}
                    onChange={(e) => setBmdc(e.target.value)}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>Bio</label>
                <div className='col-lg-9 col-xl-7'>
                  <textarea
                    rows='3'
                    className='form-control form-control-lg form-control-solid'
                    value={bio ? bio : ''}
                    onChange={(e) => setBio(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Address (Police station)
                </label>
                <div className='col-lg-9 col-xl-7'>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your address'
                    value={thana ? thana : ''}
                    onChange={(e) => setThana(e.target.value)}
                  /> */}
                  <select
                    className='form-control form-control-lg form-control-solid meal_select'
                    onChange={(e) => setThana(e.target.value)}
                    value={thana}
                  >
                    <option value=''>Thana</option>
                    {thanasByDistrict[district] &&
                      thanasByDistrict[district].map((thana, index) => (
                        <option value={thana} key={index}>
                          {thana}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Address (District)
                </label>
                <div className='col-lg-9 col-xl-7'>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your address'
                    value={district ? district : ''}
                    onChange={(e) => setDistrict(e.target.value)}
                  /> */}
                  <select
                    className='form-control form-control-lg form-control-solid meal_select'
                    onChange={(e) => handleDistrictSelection(e.target.value)}
                    value={district}
                  >
                    <option value=''>District</option>
                    {distList &&
                      distList.map((district, index) => (
                        <option value={district} key={index}>
                          {district}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Specialization
                </label>
                <div className='col-lg-9 col-xl-7'>
                  {/* <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your specialization'
                    value={specialization ? specialization : ''}
                    onChange={(e) => setSpecialization(e.target.value)}
                  /> */}
                  <select
                    className="form-control form-control-lg form-control-solid speciality meal_select"
                    onChange={(e) => setSpecialization(e.target.value)}
                    value={specialization}
                  >
                    <option disabled={true} style={{ color: '#000' }} value=''>Speciality</option>
                    {specialities.map(item => item.label).sort().map((speciality, index) => <option key={index} style={{ color: '#000' }} value={speciality}>{speciality}</option>)}
                  </select>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Your Title
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='enter your title'
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Visiting Card
                </label>
                <div className='col-lg-9 col-xl-7'>
                  <div
                    className='image-input image-input-outline'
                    id='kt_profile_avatar'
                    style={
                      currentUser.doctor.visiting_card
                        ? {
                          backgroundImage: `url(${process.env.REACT_APP_AXIOS_BASE_URL}/${currentUser.doctor.visiting_card})`,
                        }
                        : { background: '#e6e7e8' }
                    }
                  >
                    <div className='image-input-wrapper'></div>
                  </div>
                  <div></div>
                  <div className='custom-file'>
                    <input
                      type='file'
                      className='custom-file-input'
                      accept='image/x-png,image/gif,image/jpeg'
                      onChange={(e) => setVisitingCard(e.target.files[0])}
                    />
                    <label className='custom-file-label'>
                      Change visiting card
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'></label>
                <div className='col-lg-9 col-xl-7'>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Profile;
