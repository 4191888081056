import { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from "react-icons/ai";

const Settings = () => {
  const { currentUser } = useAuth();
  const [SignatureId, setSignatureId] = useState(0);
  const [Signature, setSignature] = useState('');
  const [UploadSignature, setUploadSignature] = useState(null);


  const fetchDeleteSignature = async (opType) => {
    const token = currentUser.token;
    const DID = currentUser.doctor_id;
    // console.log(token, '  ', DID, '  ', SignatureId);
    let oplink = process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/doctor-setting/?doctor=' + DID;

    if (opType === 'DELETE') {
      oplink = process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/doctor-setting/' + SignatureId + '/';
    } else {
      opType = 'GET';
    }

    fetch(oplink, {
      method: opType,
      headers: {
        'Authorization': 'Token ' + token,
      },
    })
      .then((response) => response.text())
      .then((responseDocJson) => {
        if (responseDocJson.trim().startsWith('<')) {
          toast.error("Signature upload error !", { autoClose: 3000 });
          console.log(responseDocJson);
        } else {
          if (opType === 'DELETE') {
            // console.log('Delete response', responseDocJson);
            setSignatureId(0);
            setSignature('');
            setUploadSignature(null);
            toast.success("Signature has been removed successfully !", { autoClose: 3000 });
          } else {
            // console.log('Get response', responseDocJson);
            responseDocJson = JSON.parse(responseDocJson);
            if (!!responseDocJson.results.length) {
              setSignatureId(responseDocJson.results[0].id);
              setSignature(responseDocJson.results[0].signature);
            }

          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const putPostSignature = () => {
    const token = currentUser.token;
    const DID = currentUser.doctor_id;
    // console.log(token, '  ', DID, '  ', SignatureId);

    const method = !Signature ? 'POST' : 'PUT';
    const apiPath = !Signature ? process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/doctor-setting/?doctor=' + DID + '/' :
      process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/doctor-setting/' + SignatureId + '/';

    // console.log(token, '--', DID, '--', method, '--', apiPath);

    let form_data = new FormData();
    form_data.append('signature', UploadSignature);
    form_data.append('doctor', DID);

    fetch(apiPath, {
      method: method,
      body: form_data,
      headers: {
        'Authorization': 'Token ' + token,
      },
    })
      .then((response) => response.text())
      .then((responseDocJson) => {
        // console.log('put-post: ',responseDocJson);
        if (responseDocJson.startsWith('<')) {
          console.log(responseDocJson);
          toast.error("Signature upload error !", { autoClose: 3000 });
        } else {
          responseDocJson = JSON.parse(responseDocJson);
          // console.log('put-post',responseDocJson);
          if (responseDocJson.detail === 'Not found.' || (typeof responseDocJson.signature !== 'string')) {
            toast.error("Signature upload error !", { autoClose: 3000 });
          } else {
            setSignature(responseDocJson.signature);
            setUploadSignature(null);
            toast.success("Signature has been updated successfully !", { autoClose: 3000 });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Signature upload error. Error is: " + error.message, { autoClose: 3000 });
      });
  }

  useEffect(() => {
    // console.log(currentUser);
    setUploadSignature(null);
    fetchDeleteSignature('GET');
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Signature && Signature.size > 1024000) {
      toast.error(`Signature can't be exceed more than 1MB!`, {
        autoClose: 3000,
      });
    } else {
      putPostSignature();
    }
  };

  return (
    <div className='row'>
      <div className='col-md-8'>
        <div className='card card-custom card-stretch'>
          <div className='card-header py-3'>
            <div className='card-title align-items-start flex-column'>
              <h3 className='card-label font-weight-bolder text-dark'>
                Profile Settings
              </h3>
              <span className='text-muted font-weight-bold font-size-sm mt-1'>
                Update your profile settings informaiton
              </span>
            </div>
          </div>
          <div className='card-body'>
            <form onSubmit={handleSubmit}>

              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'>
                  Signature
                </label>
                <div className='col-lg-9 col-xl-6'>
                  <div
                    className='image-input image-input-outline'
                    id='kt_profile_avatar'
                  // style={
                  //   currentUser.doctor.visiting_card
                  //     ? {
                  //         backgroundImage: `url(${process.env.REACT_APP_AXIOS_BASE_URL}/${currentUser.doctor.visiting_card})`,
                  //       }
                  //     : { background: '#e6e7e8' }
                  // }
                  >
                    {!!Signature && <div onClick={() => {
                      if(window.confirm('Are you sure to remove the signature?')){
                        fetchDeleteSignature('DELETE');
                      }
                    }} style={{ position: 'absolute', top: -5, right: -5, color: '#7C8399', cursor: 'pointer', }}>
                      <AiOutlineCloseCircle size={20} />
                    </div>}
                    <div className='image-input-wrapper' style={{ overflow: 'hidden', padding: '8px', height: '100px', width: 'auto', minWidth: '268px', display:'flex', justifyContent: 'center' }}>
                      <img src={`${Signature}`} style={{ height: '100%',/* width: '100%',*/ borderRadius: '0.42rem', }} alt='' />
                    </div>
                  </div>
                  <div></div>
                  <div className='custom-file' style={{marginTop: 5}}>
                    <input
                      type='file'
                      className='custom-file-input'
                      accept='image/x-png,image/gif,image/jpeg'
                      onChange={(e) => {
                        setUploadSignature(e.target.files[0]);
                      }}
                      // onInput={(e)=>{
                      //   console.log('input: ',e);
                      // }}
                    />
                    <label className='custom-file-label'>
                      {!UploadSignature ? (Signature ? 'Update your signature' : 'Upload signature') : UploadSignature.name}
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <label className='col-xl-3 col-lg-3 col-form-label'></label>
                <div className='col-lg-9 col-xl-6'>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Settings;
