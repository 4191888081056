import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const history = useHistory();

  const login = (username, password) => {
    axios
      .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/login/`, {
        username: username,
        password: password,
      })
      .then((resp) => {
        let result = resp.data;
        axios.interceptors.request.use((config) => {
          const token = 'Token ' + result.token;
          config.headers.Authorization = token;
          return config;
        });
        // console.log(result);
        axios
          .get(
            `${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor_by_user/${result.user_id}`
          )
          .then((resp) => {
            // console.log(resp.data);
            let forLocalstorage = {
              doctor: resp.data,
              doctor_id: result.doctor_id,
              email: result.email,
              token: result.token,
              user_id: result.user_id,
            };
            setCurrentUser(forLocalstorage);
            localStorage.setItem('user-info', JSON.stringify(forLocalstorage));
            history.push('/');
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log(error.response.data.non_field_errors[0]);
          toast.error(error.response.data.non_field_errors[0], {
            autoClose: 2000,
          });
        } else {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 2000,
          });
        }
      });
  };

  const register = (
    username,
    email,
    password,
    confirmPassword,
    fullName,
    mobile,
    Speciality,
    BMDC
  ) => {
    axios
      .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/auth/registration/`, {
        username: username,
        // email: email,
        email: username,
        password1: password,
        password2: password,
        // password2: confirmPassword,
      })
      .then((resp) => {
        // console.log(resp.data);
        const key = resp.data.key;
        axios.interceptors.request.use((config) => {
          const token = 'Token ' + key;
          config.headers.Authorization = token;
          return config;
        });
        axios
          .get(`${process.env.REACT_APP_AXIOS_BASE_URL}/auth/user/`, {
            headers: {
              Authorization: `Token ${key}`,
            },
          })
          .then((resp) => {
            // console.log(resp.data);
            const user_id = resp.data.pk;
            const email = resp.data.email;
            console.log('doc');
            axios
              .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor/`, {
                full_name: fullName,
                bmdc_number: BMDC,
                speaciality: Speciality,
                mobile_number: mobile,
                user: user_id,
                email: email,
              })
              .then((resp) => {
                console.log(resp.data);
                let forLocalstorage = {
                  doctor: resp.data,
                  doctor_id: 22,
                  email: email,
                  token: key,
                  user_id: user_id,
                };
                // console.log(forLocalstorage);
                setCurrentUser(forLocalstorage);
                localStorage.setItem(
                  'user-info',
                  JSON.stringify(forLocalstorage)
                );
                // history.push('/');
                login(username, password);
              })
              .catch((error) => {
                console.log(error.response.data);
              });
          });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.username) {
            toast.error(error.response.data.username[0], {
              autoClose: 2000,
            });
          } else if (error.response.data.email) {
            toast.error(error.response.data.email[0], {
              autoClose: 2000,
            });
          } else if (error.response.data.password1) {
            toast.error(error.response.data.password1[0], {
              autoClose: 2000,
            });
          }
        } else {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 2000,
          });
        }
      });
  };

  const logout = () => {
    axios
      .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/auth/logout/`)
      .then(() => {
        localStorage.clear();
        window.location.href = '/login';
        // window.location.href = '/home';
      })
      .catch((error) => {
        toast.error('Sorry something went wrong, please try again later.', {
          autoClose: 2000,
        });
      });
  };

  const updateProfile = (
    fullName,
    phone,
    bmdc,
    bio,
    thana,
    district,
    specialization,
    title,
    profilePic,
    visitingCard
  ) => {
    axios
      .put(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor/${currentUser.doctor_id}/`,
        {
          address_district: district,
          address_thana: thana,
          bio: bio,
          bmdc_number: bmdc,
          full_name: fullName,
          mobile_number: phone,
          speaciality: specialization,
          title: title,
        }
      )
      .then((resp) => {
        console.log(resp.data);
        currentUser.doctor = resp.data;
        localStorage.clear();
        localStorage.setItem('user-info', JSON.stringify(currentUser));
        toast.success('Profile updated successfully!', {
          autoClose: 2000,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          logout();
        } else {
          toast.error('Sorry something went wrong, please try again later.', {
            autoClose: 2000,
          });
        }
      });

    const data = new FormData();
    data.append('profile_pic', profilePic);
    axios
      .put(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor/${currentUser.doctor_id}/`,
        data
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.profile_pic !== 'null') {
          // console.log('new image');
          currentUser.doctor.profile_pic = resp.data.profile_pic;
          localStorage.clear();
          localStorage.setItem('user-info', JSON.stringify(currentUser));
          history.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const datas = new FormData();
    datas.append('visiting_card', visitingCard);
    axios
      .put(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor/${currentUser.doctor_id}/`,
        datas
      )
      .then((resp) => {
        // console.log(resp.data);
        if (resp.data.visiting_card !== 'null') {
          // console.log('new image');
          currentUser.doctor.visiting_card = resp.data.visiting_card;
          localStorage.clear();
          localStorage.setItem('user-info', JSON.stringify(currentUser));
          history.push('/');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      const data = JSON.parse(localStorage.getItem('user-info'));
      setCurrentUser(data);
      axios.interceptors.request.use((config) => {
        const token = 'Token ' + data.token;
        config.headers.Authorization = token;
        return config;
      });
    }
  }, []);

  const value = {
    currentUser,
    login,
    register,
    logout,
    updateProfile,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
