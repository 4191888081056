import PatientLoading from '../components/Loading/PatientLoading';
import Sidebar from '../components/Sidebar';
import useFetch from '../useFetch';
import Skeleton from 'react-loading-skeleton';
import SignlePatient from '../components/SignlePatient';
import { useState } from 'react';

const Patients = () => {
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchVisible, setSearchVisible] = useState(5);
  const [searchSeeMore, setSearchSeeMore] = useState(true);

  const {
    data: patients,
    showSeeMore,
    handleLoadMore,
    visible,
    isLoaging,
  } = useFetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/`);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length) {
      setShowSearch(true);
      const filterPatients = patients.filter((patient) => {
        return (
          patient.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          patient.mobile.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      if (filterPatients.length < 6) {
        setSearchSeeMore(false);
      } else {
        setSearchSeeMore(true);
      }
      setSearchResult(filterPatients);
      console.log(filterPatients.length);
    } else {
      setShowSearch(false);
      setSearchVisible(5);
    }
  };

  const handleSearchLoadMore = () => {
    setSearchVisible(searchVisible + 5);
    const patientLength = searchResult.length;
    const noeShownig = searchVisible + 5;
    if (patientLength <= noeShownig) {
      setSearchSeeMore(false);
    }
  };

  return (
    <div className='row myrx'>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder='Search Patients (Name or Mobile Number)'
              value={searchText}
              onChange={handleSearch}
            />
            {isLoaging ? (
              <div>
                <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
                  <Skeleton width={175} />
                </h5>
                <PatientLoading />
                <PatientLoading />
                <PatientLoading />
              </div>
            ) : (
              <div>
                {!showSearch ? (
                  <div>
                    {!patients.length && (
                      <h5 className='mt-8 mb-4'>No patient found.</h5>
                    )}
                    {patients && (
                      <div>
                        {patients.length !== 0 && (
                          <h5
                            className='mt-8 mb-4'
                            style={{ fontSize: '18px' }}
                          >
                            {patients.length}+ Patients Found
                          </h5>
                        )}
                        <div className='row lists'>
                          {patients.slice(0, visible).map((patient, i) => (
                            <SignlePatient patient={patient} key={i} />
                          ))}
                          {showSeeMore && (
                            <div className='col-12'>
                              <p className='text-center mb-0'>
                                <span
                                  className='font-weight-bolder font-size-lg pointer text-primary'
                                  onClick={handleLoadMore}
                                >
                                  See More
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div className='row lists mt-8'>
                      {searchResult &&
                        searchResult
                          .slice(0, searchVisible)
                          .map((patient, i) => (
                            <SignlePatient patient={patient} key={i} />
                          ))}

                      {searchSeeMore && (
                        <div className='col-12'>
                          <p className='text-center mb-0'>
                            <span
                              className='font-weight-bolder font-size-lg pointer text-primary'
                              onClick={handleSearchLoadMore}
                            >
                              See More
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Patients;
