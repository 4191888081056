import Sidebar from '../components/Sidebar';
// import useFetch from '../useFetch';
import useFetchRecursive from '../useFetchRecursive';
import MedicineLoading from '../components/Loading/MedicineLoading';
import Skeleton from 'react-loading-skeleton';
import { useState, useEffect } from 'react';
import MedicineItem from '../components/MedicineItem';
import axios from 'axios';

const Medicines = () => {
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchCount, setSearchCount] = useState(0);
  const [searchNext, setSearchNext] = useState('');
  const [searchVisible, setSearchVisible] = useState(5);
  const [searchSeeMore, setSearchSeeMore] = useState(true);
  const [isFirstSearch, setIsFirstSearch] = useState(true);


  const [medicines, setMedicines] = useState([]);
  const [showSeeMore, setShowSeeMore] = useState(true);
  const [visible, setVisible] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState('');



  // const {
  //   data: medicines,
  //   showSeeMore,
  //   handleLoadMore,
  //   visible,
  //   fetchData,
  //   isLoading,
  //   count, 
  //   next
  // } = useFetchRecursive(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=500`);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (e.target.value.length) {
      setShowSearch(true);
      const filterMedicines = medicines.filter((medicine) => {
        return (
          medicine.name.toLowerCase().includes(e.target.value.toLowerCase()) 
          // ||
          // medicine.genric
          //   .toLowerCase()
          //   .includes(e.target.value.toLowerCase())
        );
      });
      if (filterMedicines.length < 6 && !next) {
        setSearchSeeMore(false);
      } else {
        setSearchSeeMore(true);
      }
      setSearchResult(filterMedicines);
      // console.log(filterMedicines.length);
    } else {
      setShowSearch(false);
      setSearchVisible(5);
    }
  };
  
  const handleFirstSearch = async() =>{
    fetch(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?search=Napa&limit=${count}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    .then(response => response.json())
      .then(response => {
        // console.log('medicines: ', response.results)
        setSearchResult(response.results);
        setSearchCount(response.count);
        setSearchNext(response.next);
        setIsLoading(false);
        setIsFirstSearch(false);
      })
      .catch(err => {
        console.log('medicine error: ', err);
        setIsFirstSearch(false);
        setIsLoading(false);
      })
  }

  const handleSearchLoadMore = async () => {
    const medLength = searchResult.length;
    const nowShownig = searchVisible + 5;
    if (medLength <= nowShownig) {
      // console.log('here', searchNext, searchCount);
      if (isFirstSearch) await handleFirstSearch();
      else setSearchSeeMore(false);
    }
    setSearchVisible(searchVisible + 5);
  };

  
  const handleLoadMore = async () => {
    if(visible >= medicines.length){
        if(next) await fetchMedicine(next);
        else setShowSeeMore(false);
    }
    
    setVisible(visible + 5);
    const postsLength = medicines.length;
    const nowShowing = visible + 5;
    
    if (postsLength >= count) {
      setShowSeeMore(false);
    }
  };

  const fetchMedicine = async (url) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    .then(response => response.json())
      .then(response => {
        // console.log('medicines: ', response.results)
        setMedicines(response.results);
        setCount(response.count);
        setNext(response.next);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('medicine error: ', err);
        setIsLoading(false);
      })
  }


  useEffect(() => {
    // console.log(medicines);
    setIsLoading(true);
    fetchMedicine(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=500`);
  }, []);


  return (
    <div className='row myrx search'>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder='Search Medicines (Name, Gener etc)'
              value={searchText}
              onChange={handleSearch}
            />
            {isLoading ? (
              <div>
                <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
                  <Skeleton width={230} />
                </h5>
                <div className='row lists'>
                  <MedicineLoading />
                  <MedicineLoading />
                  <MedicineLoading />
                  <MedicineLoading />
                  <MedicineLoading />
                  <div className='col-12'>
                    <p className='text-center mb-0'>
                      <span className='font-size-lg'>
                        <Skeleton width={70} />
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {!showSearch ? (
                  <div>
                    {medicines && (
                      <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
                        {count}+ Medicines Found
                      </h5>
                    )}
                    <div className='row lists'>
                      {medicines.length > 0 &&
                        medicines
                          .slice(0, visible)
                          .map((medicine, i) => (
                            <MedicineItem medicine={medicine} key={i} />
                          ))}

                      {showSeeMore && (
                        <div className='col-12'>
                          <p className='text-center mb-0'>
                            <span
                              className='font-weight-bolder font-size-lg pointer text-primary'
                              onClick={handleLoadMore}
                            >
                              See More
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='row lists mt-8'>
                    {searchResult &&
                      searchResult
                        .slice(0, searchVisible)
                        .map((medicine, i) => (
                          <MedicineItem medicine={medicine} key={i} />
                        ))}

                    {searchSeeMore && (
                      <div className='col-12'>
                        <p className='text-center mb-0'>
                          <span
                            className='font-weight-bolder font-size-lg pointer text-primary'
                            onClick={handleSearchLoadMore}
                          >
                            See More
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-md-4 position-sticky'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Medicines;
