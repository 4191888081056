import { useAuth } from '../context/AuthContext';
import moment from 'moment';
import PBYLogo from '../assets/img/E-Laj_Clrd2_landscape.png';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './CreatePreviewNewStyle.css';

const CreatePreviewNew = ({
  patientName,
  patientDob,
  patientAge,
  patientAgeType,
  patientPhone,
  patientGender,
  patientThana,
  patientDistrict,
  symptoms,
  signs,
  dx,
  investigations,
  medications,
  advice,
  followupDate,
}) => {
  const { currentUser } = useAuth();
  const [now, setNow] = useState('');
  const [Signature, setSignature] = useState('');

  useEffect(() => {
    fetchSignature(currentUser.doctor_id);
  }, [currentUser.doctor_id]);

  const fetchSignature = (doctor_id) => {
    axios
      .get(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/v2/doctor-setting/?doctor=${doctor_id}`
      )
      .then((response) => {
        const data = response.data.results.length
          ? response.data.results
          : response.data;
        setSignature(data[0].signature);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setInterval(() => {
    let time = moment().format('DD MMM YYYY, hh:mm A');
    // console.log(time);
    setNow(time);
  }, 1000);

  const dxToString = (array) => {
    const newDx = array.map((dx) => dx.value);
    const string = newDx.join(', ');
    return string;
  };

  return (
    <div
      className='preview-prescription-container'
      id='prescription_preview'
      style={{ borderWidth: 1 }}
    >
      <div className='preview-header-wrapper'>
        <div className='preview-header-partL'>
          {/* <div className="preview-logo-container">
                            <div className="preview-logo-wrapper">
                                <img src={LogoImg} alt="" />
                            </div>
                            <div className="preview-logo-title">e-laj</div>
                        </div> */}
        </div>
        <div className='preview-header-partR'>
          {currentUser.doctor && (
            <div className='preview-header-textR'>
              <div className='preview-doc-name'>
                {currentUser.doctor.title + ' ' + currentUser.doctor.full_name}
              </div>
              <div className='preview-doc-speciality'>
                {currentUser.doctor.speaciality}
              </div>
              {currentUser.doctor.bio && (
                <div className='preview-doc-professional-bio'>
                  {currentUser.doctor.bio}
                </div>
              )}
              <div className='preview-doc-contact-section'>
                {currentUser.doctor.mobile_number && (
                  <div className='preview-doc-mobile'>
                    {currentUser.doctor.mobile_number}
                  </div>
                )}
                ,
                {currentUser.doctor.email && (
                  <div className='preview-doc-email'>
                    {currentUser.doctor.email}
                  </div>
                )}
              </div>
              {currentUser.doctor.address_district && (
                <div className='preview-doc-address'>
                  {currentUser.doctor.address_thana + ', '}{' '}
                  {currentUser.doctor.address_district}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className='preview-patient-wrapper'>
        <div className='preview-patient-details'>
          <div className='preview-patient-part'>
            Name: {patientName && <span>{patientName}</span>}
          </div>
          <div className='preview-patient-part'>
            Age:{' '}
            {patientAge && (
              <span>
                {patientAge} {patientAgeType}
              </span>
            )}
          </div>
          <div className='preview-patient-part'>
            Rx. ID: {<span>#739129</span>}
          </div>
          <div className='preview-patient-part'>
            Created On: <span>{now ? now : '11 May 2021, 04:25 PM'}</span>
          </div>
        </div>
      </div>

      <div className='preview-prescription-details-container'>
        <div className='preview-details-leftside'>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'> C/C.</div>
            <div className='preview-prescription-item-content'>
              {!symptoms ? '' : symptoms}
            </div>
          </div>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'> R. History.</div>
            <div className='preview-prescription-item-content'>
              {!signs ? '' : signs}
            </div>
          </div>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'> O/E.</div>
            <div className='preview-prescription-item-content'>
              {!investigations ? '' : investigations}
            </div>
          </div>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'>
              {' '}
              Investigations.
            </div>
            <div
              className='preview-prescription-item-content'
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'column',
              }}
            >
              {!dx
                ? ''
                : dx.map((item, index) => (
                    <div key={index} className='preview-investigation-wrapper'>
                      * {item.value}
                    </div>
                  ))}
            </div>
          </div>
        </div>

        <div className='preview-details-divider'></div>

        <div className='preview-details-rightside'>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'>Rx.</div>
            <div className='preview-prescription-item-content' id='rx-content'>
              {!medications
                ? ''
                : medications.map((item, index) => (
                    <div
                      key={'' + index}
                      className='preview-medication-wrapper'
                    >
                      <div className='preview-medication-brief'>
                        <div className='preview-medicine-name'>
                          {item.medication_name},
                        </div>
                        {item.medication_type !== 'Fleet Enema' && (
                          <div className='preview-medication-generic-strength'>
                            <div className='preview-medicine-strength'>
                              {item.strength}
                            </div>
                          </div>
                        )}
                        <div className='preview-medicine-type'>
                          ({item.medication_type})
                        </div>
                      </div>
                      {/* {item.medication_type !== 'Fleet Enema' && <div className="preview-medication-generic-strength">
                                                <div className="preview-medicine-generic">{item.genetic}</div>
                                                <div className="preview-medicine-strength">{item.strength}</div>
                                            </div>} */}
                      {(item.medication_type === 'Capsule' ||
                        item.medication_type === 'Tablet') && (
                        <div className='preview-medication-schedule'>
                          <span>
                            {item.medication_schedule_monring} +{' '}
                            {item.medication_schedule_afternoon} +{' '}
                            {item.medication_schedule_evening}
                          </span>
                        </div>
                      )}
                      <div className='preview-medication-does-meal-days'>
                        {item.medication_type !== 'Capsule' &&
                          item.medication_type !== 'Tablet' && (
                            <div className='preview-medication-doseDrop'>
                              <span>{item.dose_drops_time_puff},</span>
                            </div>
                          )}
                        {(item.medication_type === 'Capsule' ||
                          item.medication_type === 'Tablet' ||
                          item.medication_type === 'Syrup') && (
                          <div className='preview-medication-mealtime'>
                            <span>{item.medication_with_meal},</span>
                          </div>
                        )}
                        {item.medication_type !== 'Nebulization' && (
                          <div className='preview-medication-days'>
                            <span>{item.days} Days</span>
                          </div>
                        )}
                      </div>
                      {item.medication_type === 'Nebulization' && (
                        <div className='preview-medication-mixture'>
                          <span>{item.mixture_details}</span>
                        </div>
                      )}
                      {item.advice && (
                        <div className='preview-medication-advice'>
                          Advice: <span> {item.advice}</span>
                        </div>
                      )}
                    </div>
                  ))}
            </div>
          </div>
          <div className='preview-prescription-item'>
            <div className='preview-prescription-item-title'>Advice.</div>
            <div
              className='preview-prescription-item-content'
              style={{ fontWeight: 400 }}
            >
              {!advice ? '' : advice}
            </div>
          </div>
          <div
            className='preview-prescription-item'
            style={{ marginBottom: 0 }}
          >
            <div className='preview-prescription-item-title'>
              Followup Date.
            </div>
            <div className='preview-prescription-item-content'>
              {followupDate === 'Invalid date' ? '' : followupDate}
            </div>
          </div>
          <div className='preview-prescription-item' id='preview-poweredby-sec'>
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <div className='preview-prescription-item-title'>Powered By.</div>
              <div className='preview-logo-container'>
                <div className='preview-logo-wrapper'>
                  <img style={{ marginLeft: '0px' }} src={PBYLogo} alt='' />
                </div>
                {/* <div className="preview-logo-title">e-laj</div> */}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              <div className='preview-signature-wrapper'>
                <div className='preview-signature-image-wrapper'>
                  {!!Signature && (
                    <img
                      src={Signature}
                      alt='alteration'
                      className='preview-signature-image'
                    />
                  )}
                </div>
                <div className='preview-signature-dash'>
                  ---------------------------
                </div>
                <div className='preview-signature-footer'>Signature</div>
              </div>
            </div>
          </div>
          <div className='preview-signature-container'>
            {/* <div className="preview-signature-wrapper">
                                <div className="preview-signature-image-wrapper">
                                    {!!Signature && <img src={Signature} alt="alteration" className='preview-signature-image' />}
                                </div>
                                <div className="preview-signature-dash">
                                    ------------------------------
                                </div>
                                <div className="preview-signature-footer">
                                    Signature
                                </div>
                            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePreviewNew;
