import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

const AdviceList = ({ setAdvice, advList, setMainAdvice }) => {
  const [options, setOptions] = useState([]);
  
  const fetchDxRecursively = (advicelink) => {
    // console.log(advicelink);
    fetch(advicelink, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseDataObj) => {
        var responseData = responseDataObj.results;
        setOptions([...options, ...responseData.map(item => ({value: item.text, label: item.text}))]);
        setMainAdvice([...options, ...responseData.map(item => ({value: item.text, label: item.text}))]);
        
        if(!!responseDataObj.next) console.log("Loading ");
        else console.log("Finished ")
        
        if (!!responseDataObj.next) fetchDxRecursively(responseDataObj.next);
      })
      .catch((error) => {
        console.error('advice: ', error);
      });
  }
  
  useEffect(()=>{
    if(!advList) fetchDxRecursively(process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/advice/?limit=200');
    else setOptions(advList);
  },[]);

  return (
    <CreatableSelect
      isMulti
      options={options}
      onChange={(e) => setAdvice(e)}
      placeholder={
        <p
          className='mb-0'
          style={{
            fontSize: '1.08rem',
            color: '#c3c3cd',
          }}
        >
          Advice
        </p>
      }
    />
  );
};

export default AdviceList;
