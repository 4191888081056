import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../assets/img/logos/E-Laj_Clrd2.png';

export class ForgotPassRetrieve extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      otp: '',
      password: '',
      cpassword: '',
      timeCounter: '',
      isOtpSend: false,
      isOtpSendFailed: false,
      isOtpValidate: false,
      isOtpSendCounter: false,
      loading: false,
      isOtpSendCountLimit: false,
    }
  }

  componentDidMount() {

    // localStorage.removeItem('otp_details');
    const otpDetails = localStorage.getItem('otp_details');
    // console.log('otp details', otpDetails);
    if (otpDetails) {
      const otpDetailsObj = JSON.parse(otpDetails);
      const minutes = this.calculateMinutes(new Date(otpDetailsObj.created_on));

      if (1440 < minutes) {
        localStorage.removeItem('otp_details');
        this.setState({ isOtpSend: false, isOtpSendCountLimit: false });
      } else if (1440 > minutes && otpDetailsObj.OtpSendCount < 2) {
        if (minutes <= 3) {
          this.setState({ isOtpSend: true, email: otpDetailsObj.email });
        } else {
          this.setState({ isOtpSend: false, });
        }
      } else if (1440 > minutes && otpDetailsObj.OtpSendCount >= 2) {
        this.setState({ isOtpSendCountLimit: true, isOtpSend: false, isOtpSendFailed: false, isOtpValidate: false });
        // this.banAlert();
      }
    }
    else {
      this.setState({ isOtpSend: false, });
    }

    // this.setState({ isOtpSend: true, isOtpValidate: true });
  }


  banAlert = () => {
    this.setState({ isOtpSendCountLimit: true, isOtpSend: false, isOtpSendFailed: false, isOtpValidate: false, email: '', otp: '' });
    return (
      alert("Warning !\nOTP sending limit has been expired, Please try again 24 hours later.")
    )
  }

  ValidateEmail = (mail) => {
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return false;
    } else {
      return true;
    }
  }

  calculateMinutes = (oldTime) => {
    const now = new Date();
    const difference = now.getTime() - oldTime.getTime();
    const resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
  };

  sendOTP = (e) => {
    e.preventDefault();

    const { email } = this.state;
    this.setState({ isOtpSend: false });

    if (!email) {
      toast.error("No Email Found. Please provide one.", { autoClose: 3000, });
      return;
    }
    else if (!this.ValidateEmail(email)) {
      toast.error("Please provide a valid email.", { autoClose: 3000, });
      return;
    }

    const otpDetails = localStorage.getItem('otp_details');

    //if tried before
    if (otpDetails) {
      // console.log('get otp details: ', otpDetails);
      const otpDetailsObj = JSON.parse(otpDetails);
      if (otpDetailsObj.OtpSendCount < 2) {             // if tried less than three times
        this.setState({ loading: true });
        fetch(process.env.REACT_APP_AXIOS_BASE_URL + '/api/send_otp_password/' + email + '/', {
          method: 'GET',
        })
          .then((response) => response.json())
          .then(async (responseJson) => {
            if (!!responseJson.status) {
              otpDetailsObj.OtpSendCount++;
              otpDetailsObj.created_on = new Date();
              otpDetailsObj.email = email;
              localStorage.setItem('otp_details', JSON.stringify(otpDetailsObj));
              // console.log('put otp details: ', JSON.stringify(otpDetailsObj));

              this.setState({ isOtpSend: true, isOtpSendFailed: false });
              // runCounter();
              toast.success("OTP send successfull !", { autoClose: 3000, });

            }
            else {
              this.setState({ isOtpSend: false, isOtpSendFailed: true });
              toast.error("User not exist, Please register as a new user.", { autoClose: 3000, });
            }

            this.setState({ loading: false });
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.error(error);
            toast.error("OTP send failed !", { autoClose: 3000, });
          });
      }
      else {
        this.banAlert();
      }
    }
    // if this is first time
    else {
      this.setState({ loading: true });
      fetch(process.env.REACT_APP_AXIOS_BASE_URL + '/api/send_otp_password/' + email + '/', {
        method: 'GET',
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if (!!responseJson.status) {
            let otpDetailsObj = { OtpSendCount: 0, created_on: new Date(), email: email };
            localStorage.setItem('otp_details', JSON.stringify(otpDetailsObj));

            this.setState({ isOtpSend: true, isOtpSendFailed: false });
            // runCounter();
            toast.success("OTP has been send to your email!", { autoClose: 3000, });
          }
          else {
            this.setState({ isOtpSend: false, isOtpSendFailed: true });
            toast.error("User not exist, Please register as a new user.", { autoClose: 3000, });
          }

          this.setState({ loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.error(error);
          toast.error("OTP send failed !", { autoClose: 3000, });
        });
    }

  }


  validateOTP = async (e) => {
    e.preventDefault();

    const { otp, email } = this.state;
    this.setState({ loading: true });

    fetch(process.env.REACT_APP_AXIOS_BASE_URL + '/api/v2/match-otp/?code=' + otp + '&email=' + email, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.count === 1) {
          new Date(responseJson.results[0].created_at)
          if (this.calculateMinutes(new Date(responseJson.results[0].created_at)) < 3) {
            this.setState({ isOtpValidate: true })
            // localStorage.removeItem('otp_details');
            toast.success("OTP validation successfull !", { autoClose: 3000, });
          }
          else {
            this.setState({ isOtpSend: false });
            toast.error('Otp validation time expired', { autoClose: 3000, });
          }
        }
        else {
          this.setState({ isOtpValidate: false })
          toast.error("OTP validation failed !", { autoClose: 3000, });
        }
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error(error);
        toast.error("OTP validation failed !", { autoClose: 3000, });
      });
  }


  handleUpdateButton = () => {

    const { email, otp, password, cpassword } = this.state;

    if (password || cpassword) {
      if (!password) {
        toast.error('Please fill password', { autoClose: 3000, });
        return;
      }

      if (!cpassword) {
        toast.error('Please fill confirm passowrd', { autoClose: 3000, });
        return;
      }

      if (password !== cpassword) {
        toast.error('Password & Confirm password should be same.', { autoClose: 3000, });
        return;
      }

      let dataToSend = { username: email, password: password, code: otp };
      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
      formBody = formBody.join('&');

      this.setState({ loading: true });

      fetch(process.env.REACT_APP_AXIOS_BASE_URL + '/api/forgot-password', {
        method: 'POST',
        body: formBody,
        headers: {
          //Header Defination
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
        .then((response) => response.text())
        .then(async (responseJson) => {
          if (responseJson.startsWith('<')) {
            this.setState({ loading: false });
            console.log(responseJson);
            toast.error("Password update failed !", { autoClose: 3000, });
          } else {
            localStorage.removeItem('otp_details');
            responseJson = JSON.parse(responseJson);
            if (responseJson.status) {
              this.setState({ loading: false, password: '', cpassword: '' });
              toast.success(responseJson.message, { autoClose: 3000, });
              this.props.history.replace('/login');
            }
            else {
              responseJson.message ? toast.error(responseJson.message, { autoClose: 3000, }) : toast.success("Password update failed. Try again.", { autoClose: 3000, });
              this.setState({ loading: false });
            }

          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.error(error);
          toast.error("Password update failed !", { autoClose: 3000, });
        });

    }
  }



  render() {
    return (
      <div className='login_signup'>
        <div id='kt_body'
          className='header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed page-loading' >
          <div className='d-flex flex-column flex-root'>
            <div className='login login-5 login-signin-on d-flex flex-row-fluid'
              id='kt_login' >
              <div className='d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid pb-20'>
                <div className='login-form text-center text-white p-7 position-relative overflow-hidden'>
                  <div className='d-flex flex-center mb-15'>
                    <Link to='/'>
                      <img src={Logo} className='max-h-120px' alt='' />
                    </Link>
                  </div>
                  <div className='login-signin'>
                    <div className='mb-16' style={{ marginTop: '3rem' }}>
                      <h3 className='opacity-80 font-weight-normal'>Reset Password</h3>
                    </div>

                    {!this.state.isOtpValidate ?
                      <div className="first-sec">
                        <form className='form' onSubmit={e => this.sendOTP(e)} >
                          <div className='form-group position-relative d-flex align-items-center border border-logo rounded-pill border-1'>
                            <input
                              style={{ width: '80%' }}
                              disabled={this.state.isOtpSendCountLimit}
                              className='form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8'
                              type='text'
                              placeholder='Enter email'
                              onChange={(e) => this.setState({ email: e.target.value })}
                              value={this.state.email}
                            />
                            <button
                              disabled={this.state.isOtpSendCountLimit}
                              style={{ right: '8px', marginTop: '1px', cursor: this.state.isOtpSendCountLimit ? 'none' : 'pointer' }}
                              className='btn btn-pill btn-primary opacity-90 px-4 py-1 position-absolute' >
                              {this.state.isOtpSend ? 'Resend OTP' : 'Send OTP'}
                            </button>
                          </div>
                        </form>
                        <form className='form' onSubmit={e => this.validateOTP(e)} >
                          {this.state.isOtpSend &&
                            <div className='form-group'>
                              <input
                                className='form-control h-auto text-white bg-white-o-5 border border-logo rounded-pill border-1 py-4 px-8'
                                type='text'
                                placeholder='Enter OTP'
                                onChange={(e) => this.setState({ otp: e.target.value })}
                                value={this.state.otp}
                              />
                            </div>
                          }
                          {this.state.isOtpSend &&
                            <div className='form-group text-center mt-10'>
                              <button type='submit' id='kt_login_signin_submit'
                                className='btn btn-pill btn-primary opacity-90 px-15 py-3' >
                                Validate
                              </button>
                            </div>
                          }
                          {this.state.isOtpSend &&
                            <div className='mt-3'>
                              <span className='opacity-60 text-muted mr-4'>
                                An OTP has been send to your email. Please cgheck your inbox.
                              </span>
                            </div>}
                          {this.state.isOtpSendCountLimit &&
                            <div className='mt-3'>
                              <span className='opacity-60 text-muted mr-4'>
                              *Retrive password OTP sending limit has been expired, Please try again 24 hours later.
                              </span>
                            </div>}
                        </form>
                      </div>
                      :
                      <form className='form' onSubmit={(e) => { e.preventDefault(); this.handleUpdateButton() }}>
                        <div className='form-group position-relative d-flex align-items-center border border-logo rounded-pill border-1'>
                          <input
                            className='form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8'
                            type='password'
                            placeholder='New password'
                            onChange={(e) => this.setState({ password: e.target.value })}
                            value={this.state.password}
                          />
                        </div>
                        <div className='form-group'>
                          <input
                            className='form-control h-auto text-white bg-white-o-5 border border-logo rounded-pill border-1 py-4 px-8'
                            type='password'
                            placeholder='Confirm password'
                            onChange={(e) => this.setState({ cpassword: e.target.value })}
                            value={this.state.cpassword}
                          />
                        </div>
                        <div className='form-group text-center mt-10'>
                          <button id='kt_login_signin_submit'
                            className='btn btn-pill btn-primary opacity-90 px-15 py-3' >
                            Reset
                          </button>
                        </div>
                      </form>
                    }

                    {!this.state.isOtpValidate && <div className='mt-3'>
                      <span className='opacity-35 mr-4'>
                        Want to sign in now?
                      </span>
                      <Link
                        to='/login'
                        className='text-white opacity-30 font-weight-normal'
                      >
                        Sign In
                      </Link>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPassRetrieve;
