import { Link } from 'react-router-dom';
// import Logo from '../../assets/media/logos/logo-letter-13.png';
import Logo from '../../assets/img/logos/E-Laj_Clrd2.png';
import { useAuth } from '../../context/AuthContext';

const Links = () => {
  const { logout } = useAuth();
  const handleLogout = () => {
    logout();
  };

  return (
    <div>
      <div
        className='aside aside-left aside-fixed d-flex flex-column flex-row-auto'
        id='kt_aside'
      >
        <div className='brand flex-column-auto' id='kt_brand'>
          <Link to='/' className='brand-logo'>
            <img alt='Logo' className='w-40px' src={Logo} />
          </Link>
        </div>
        <div className='aside-menu-wrapper flex-column-fluid'>
          <div
            id='kt_aside_menu'
            className='aside-menu my-0'
            data-menu-vertical='1'
            data-menu-scroll='1'
            data-menu-dropdown-timeout='500'
          >
            <ul className='menu-nav'>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon2-architecture-and-city'></i>
                  <span className='menu-text'>Home</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/create' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon2-add'></i>
                  <span className='menu-text'>Create</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/my-prescriptions' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon2-medical-records-1'></i>
                  <span className='menu-text'>My&nbsp;Rx.</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/search' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon-search'></i>
                  <span className='menu-text'>Finder</span>
                </Link>
              </li>
              {/* <li className='menu-item menu-item-active mb-1'>
                <Link to='/statistics' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon2-graphic-1'></i>
                  <span className='menu-text'>Statistics</span>
                </Link>
              </li> */}
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/profile' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon-user'></i>
                  <span className='menu-text'>Profile</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/settings' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon2-gear'></i>
                  <span className='menu-text'>Settings</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <Link to='/resetpass' className='menu-link'>
                  <i style={{color: '#54b9a8'}} className='menu-icon las la-key'></i>
                  <span className='menu-text text-center'>Reset Password</span>
                </Link>
              </li>
              <li className='menu-item menu-item-active mb-1'>
                <p className='menu-link' onClick={handleLogout}>
                  <i style={{color: '#54b9a8'}} className='menu-icon flaticon-logout'></i>
                  <span className='menu-text'>Logout</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
