import React from 'react'
import './PrescriptionPreviewStyle.css';
import Skeleton from 'react-loading-skeleton';
import SnehoImg from '../../assets/img/sneho.png';

const PrescriptionPreviewSkeleton = () => {
    return (
        <div className='prescription-page'>
            <div className="prescription-container" id='prescription_preview'>
                <div className="header-wrapper">
                    <div className="header-partL">
                    </div>
                    <div className="header-partR">
                        <div className="header-textR">
                            <div className='doc-name'><Skeleton width={300} height={40} /></div>
                            <div className="doc-speciality"><Skeleton width={100} /></div>
                            <div className='doc-professional-bio'><Skeleton width={100} /></div>
                            <div className="doc-contact-section">
                                <div className='doc-mobile'><Skeleton width={100} /></div>,
                                <div className='doc-email'><Skeleton width={100} /></div>
                            </div>
                            <div className='doc-address'><Skeleton width={100} /> <Skeleton width={100} /></div>
                        </div>
                    </div>
                </div>

                <div className="patient-wrapper">
                    <div className="patient-details">
                        <div className="patient-part">
                            Name: <span><Skeleton width={100} /></span>
                        </div>
                        <div className="patient-part">
                            Age: <span><Skeleton width={100} /> </span>
                        </div>
                        <div className="patient-part">
                            Rx. ID: <span><Skeleton width={100} /></span>
                        </div>
                        <div className="patient-part">
                            Created On: <span><Skeleton width={100} /></span>
                        </div>
                    </div>
                </div>

                <div className="prescription-details-container">
                    <div className="details-leftside">
                        <div className="prescription-item">
                            <div className="prescription-item-title"> C/C.</div>
                            <div className="prescription-item-content">
                            <Skeleton width={100} />
                            </div>
                        </div>
                        <div className="prescription-item">
                            <div className="prescription-item-title"> R. History.</div>
                            <div className="prescription-item-content">
                            <Skeleton width={100} />
                            </div>
                        </div>
                        <div className="prescription-item">
                            <div className="prescription-item-title"> O/E.</div>
                            <div className="prescription-item-content">
                                <Skeleton width={100} />
                            </div>
                        </div>
                        <div className="prescription-item">
                            <div className="prescription-item-title"> Investigations.</div>
                            <div className="prescription-item-content" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                            <Skeleton width={100} />
                            </div>
                        </div>
                    </div>

                    <div className="details-divider"></div>

                    <div className="details-rightside">
                        <div className="prescription-item">
                            <div className="prescription-item-title">Rx.</div>
                            <div className="prescription-item-content" id='rx-content'>
                                {Array(2).map((item, index) => (
                                        <div key={'' + index} className="medication-wrapper">
                                            <div className="medication-brief">
                                                <div className="medicine-name"><Skeleton width={100} />,</div>
                                                <div className="medication-generic-strength">
                                                    <div className="medicine-strength"><Skeleton width={30} /></div>
                                                </div>
                                                <div className="medicine-type">(<Skeleton width={30} />)</div>
                                            </div>
                                            
                                            <div className="medication-schedule">
                                                <span><Skeleton width={100} /></span>
                                            </div>
                                            <div className="medication-does-meal-days">
                                                <div className="medication-doseDrop">
                                                    <span><Skeleton width={100} />,</span>
                                                </div>
                                                <div className="medication-mealtime">
                                                    <span><Skeleton width={100} />,</span>
                                                </div>
                                                <div className="medication-days">
                                                    <span><Skeleton width={100} /></span>
                                                </div>
                                            </div>
                                            <div className="medication-mixture">
                                                <span><Skeleton width={100} /></span>
                                            </div>
                                            <div className="medication-advice">
                                                <span> <Skeleton width={100} /></span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="prescription-item">
                            <div className="prescription-item-title">Advice.</div>
                            <div className="prescription-item-content">
                            <Skeleton width={100} />
                            </div>
                        </div>
                        <div className="prescription-item" style={{ marginBottom: 0 }}>
                            <div className="prescription-item-title">Followup Date.</div>
                            <div className="prescription-item-content">
                            <Skeleton width={100} />
                            </div>
                        </div>
                        <div className="prescription-item" id='poweredby-sec'>
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                <div className="prescription-item-title">Powered By.</div>
                                <div className="logo-container">
                                    <div className="logo-wrapper">
                                    <Skeleton width={100} height={40} />
                                    </div>
                                    {/* <div className="logo-title">e-laj</div> */}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                                <div className="signature-wrapper">
                                    <div className="signature-image-wrapper">
                                    <Skeleton width={100} height={40} />
                                    </div>
                                    <div className="signature-dash">
                                        ------------------------------
                                    </div>
                                    <div className="signature-footer">
                                        Signature
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="signature-container">
                        </div>
                    </div>
                </div>
            </div>
            {/* <><input type="text" id="urlInput" value={prescriptionURL} style={{display: 'none'}}></input></> */}
            <div className="button-container">
                <div className="copy-container" >
                    <div className="copy-wrapper">
                        Copy Rx. Url
                    </div>
                </div>
                <div className="share-container" >
                    <div className="share-wrapper">
                        Share
                    </div>
                </div>
                <div
                    className='share-container'
                >
                    <div className='share-wrapper'>Print</div>
                </div>


                <div className="download-container" >
                    <div className="download-wrapper">
                        Download
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrescriptionPreviewSkeleton
