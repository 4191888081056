export const specialities = [
    { label: 'Allergy and immunology', value: 'Allergy and immunology' },
    { label: 'Anatomical Pathology', value: 'Anatomical Pathology' },
    { label: 'Anesthesiology', value: 'Anesthesiology' },
    { label: 'Aerospace medicine', value: 'Aerospace medicine' },
    { label: 'Bariatrics', value: 'Bariatrics' },
    { label: 'Cardiology', value: 'Cardiology' },
    { label: 'Cardiovascular/Thoracic Surgery', value: 'Cardiovascular/Thoracic Surgery' },
    { label: 'Child and adolescent psychiatry', value: 'Child and adolescent psychiatry' },
    { label: 'Clinical Immunology/Allergy', value: 'Clinical Immunology/Allergy' },
    { label: 'Clinical neurophysiology', value: 'Clinical neurophysiology' },
    { label: 'Clinical Pathology', value: 'Clinical Pathology' },
    { label: 'Colorectal surgery', value: 'Colorectal surgery' },
    { label: 'Dermatology', value: 'Dermatology' },
    { label: 'Developmental pediatrics', value: 'Developmental pediatrics' },
    { label: 'Diagnostic Radiology', value: 'Diagnostic Radiology' },
    { label: 'Emergency Medicine', value: 'Emergency Medicine' },
    { label: 'Endocrinology and Metabolism', value: 'Endocrinology and Metabolism' },
    { label: 'Family Medicine', value: 'Family Medicine' },
    { label: 'Forensic pathology', value: 'Forensic pathology' },
    { label: 'Forensic psychiatry', value: 'Forensic psychiatry' },
    { label: 'Gastroenterology', value: 'Gastroenterology' },
    { label: 'General Internal Medicine', value: 'General Internal Medicine' },
    { label: 'General Surgery', value: 'General Surgery' },
    { label: 'General surgical oncology', value: 'General surgical oncology' },
    { label: 'Geriatrics', value: 'Geriatrics' },
    { label: 'Geriatric psychiatry', value: 'Geriatric psychiatry' },
    { label: 'Gynecologic oncology', value: 'Gynecologic oncology' },
    { label: 'Hematology', value: 'Hematology' },
    { label: 'Hematologic pathology', value: 'Hematologic pathology' },
    { label: 'Infectious disease', value: 'Infectious disease' },
    { label: 'Internal medicine', value: 'Internal medicine' },
    { label: 'Interventional radiology', value: 'Interventional radiology' },
    { label: 'Intensive care medicine', value: 'Intensive care medicine' },
    { label: 'Maternal-fetal medicine', value: 'Maternal-fetal medicine' },
    { label: 'Medical Biochemistry', value: 'Medical Biochemistry' },
    { label: 'Medical Genetics', value: 'Medical Genetics' },
    { label: 'Microbiology and Infectious Diseases', value: 'Microbiology and Infectious Diseases' },
    { label: 'Oncology', value: 'Oncology' },
    { label: 'Neonatology', value: 'Neonatology' },
    { label: 'Nephrology', value: 'Nephrology' },
    { label: 'Neurology', value: 'Neurology' },
    { label: 'Neuropathology', value: 'Neuropathology' },
    { label: 'Neurosurgery', value: 'Neurosurgery' },
    { label: 'Nuclear Medicine', value: 'Nuclear Medicine' },
    { label: 'Obstetrics/Gynecology', value: 'Obstetrics/Gynecology' },
    { label: 'Occupational medicine', value: 'Occupational medicine' },
    { label: 'Ophthalmology', value: 'Ophthalmology' },
    { label: 'Orthopedic Surgery', value: 'Orthopedic Surgery' },
    { label: 'Oral and maxillofacial surgery', value: 'Oral and maxillofacial surgery' },
    { label: 'Otolaryngology', value: 'Otolaryngology' },
    { label: 'Otorhinolaryngology', value: 'Otorhinolaryngology' },
    { label: 'Palliative care', value: 'Palliative care' },
    { label: 'Pathology', value: 'Pathology' },
    { label: 'Pediatrics', value: 'Pediatrics' },
    { label: 'Pediatric allergy and immunology', value: 'Pediatric allergy and immunology' },
    { label: 'Pediatric cardiology', value: 'Pediatric cardiology' },
    { label: 'Pediatric emergency medicine', value: 'Pediatric emergency medicine' },
    { label: 'Pediatric endocrinology', value: 'Pediatric endocrinology' },
    { label: 'Pediatric gastroenterology', value: 'Pediatric gastroenterology' },
    { label: 'Pediatric hematology and oncology', value: 'Pediatric hematology and oncology' },
    { label: 'Pediatric infectious disease', value: 'Pediatric infectious disease' },
    { label: 'Pediatric nephrology', value: 'Pediatric nephrology' },
    { label: 'Pediatric respiratory medicine', value: 'Pediatric respiratory medicine' },
    { label: 'Pediatric rheumatology', value: 'Pediatric rheumatology' },
    { label: 'Pediatric surgery', value: 'Pediatric surgery' },
    { label: 'Physical Medicine and Rehabilitation', value: 'Physical Medicine and Rehabilitation' },
    { label: 'Plastic, reconstructive and aesthetic surgery', value: 'Plastic, reconstructive and aesthetic surgery' },
    { label: 'Psychiatry', value: 'Psychiatry' },
    { label: 'Public Health and Preventive Medicine', value: 'Public Health and Preventive Medicine' },
    { label: 'Radiation Oncology', value: 'Radiation Oncology' },
    { label: 'Radiology', value: 'Radiology' },
    { label: 'Reproductive endocrinology and infertility', value: 'Reproductive endocrinology and infertility' },
    { label: 'Respiratory medicine', value: 'Respiratory medicine' },
    { label: 'Rheumatology', value: 'Rheumatology' },
    { label: 'Sports medicine', value: 'Sports medicine' },
    { label: 'Thoracic surgery', value: 'Thoracic surgery' },
    { label: 'Toxicology', value: 'Toxicology' },
    { label: 'Neuroradiology', value: 'Neuroradiology' },
    { label: 'Urology', value: 'Urology' },
    { label: 'Vascular surgery', value: 'Vascular surgery' },

  ]