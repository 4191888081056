import React, { useState, useEffect } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

const PrescriptionDx = ({ setDx }) => {
  // const options = [
  //   { value: 'Blood Test', label: 'Blood Test' },
  //   { value: 'MRI', label: 'MRI' },
  // ];
  const [options, setOptions] = useState([]);
  const [limit, setLimit] = useState(20);
  
  const fetchDxRecursively = (dxlink) => {
    // console.log(dxlink);
    fetch(dxlink, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((responseDataObj) => {
        var responseData = responseDataObj.results;
        setOptions([...options, ...responseData.map(item => ({value: item.name, label: item.name}))]);
        // DXS == '' ? setDxSarchedDataSource([...dxSarchedDataSource, ...responseData]) : searchDX(DXS);

        if(!!responseDataObj.next) console.log("Loading ");
        else console.log("Finished ")
        
        if (!!responseDataObj.next) fetchDxRecursively(responseDataObj.next);
      })
      .catch((error) => {
        console.error('dx: ', error);
      });
  }
  
  useEffect(()=>{
    fetchDxRecursively(process.env.REACT_APP_AMARLAB_API + '/diagnostic/diagnostic-test/?limit=300');
  },[]);
  
  const fetchDx = async(inputValue) => {
    const respPromise = await fetch(`${process.env.REACT_APP_AMARLAB_API}/diagnostic/diagnostic-test/?limit=${limit}&search=${inputValue ? inputValue : ''}`, { method: 'GET' })
      .catch((error) => {
        console.log(error);
      });
      const resp = await respPromise.json();
      const newArray = resp.results.map((dxItem) => {
        return {
          ...dxItem,
          value: dxItem.name,
          label: `${dxItem.name}`,
        };
      });
      if(!inputValue){
        setOptions(newArray);
      }
      // setMedicines(newArray);
      return newArray;
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(fetchDx(inputValue));
      }, 1000);
    });

  return (
    // <CreatableSelect
    //   isMulti
    //   options={options}
    //   onChange={(e) => setDx(e)}
    //   placeholder={
    //     <p
    //       className='mb-0'
    //       style={{
    //         fontSize: '1.08rem',
    //         color: '#c3c3cd',
    //       }}
    //     >
    //       Investigations. (e.g. Blood Test, MRI)
    //     </p>
    //   }
    // />
    <AsyncCreatableSelect
      // value={optionValue}
      // defaultOptions={optionValue}
      isMulti
      // styles={style}
      loadOptions={promiseOptions}
      defaultOptions={options}
      onChange={(e) => setDx(e)}
      // onInputChange={handleInputChange}
      placeholder={
        <p
          className='mb-0'
          style={{
            fontSize: '1.08rem',
            color: '#c3c3cd',
          }}
        >
          Investigations. (e.g. Blood Test, MRI)
        </p>
      }
    />
  );
};

export default PrescriptionDx;
