// import Logo from '../assets/media/logos/logo-letter-13.png';
import Logo from '../assets/img/logos/E-Laj_Clrd2.png';
import '../assets/css/pages/login/classic/login-5.css';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const { login } = useAuth();

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      history.push('/');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email === '') {
      toast.error('Please enter email!', {
        autoClose: 2000,
      });
    } else if (password === '') {
      toast.error('Please enter password!', {
        autoClose: 2000,
      });
    } else {
      login(email, password);
    }
  };

  return (
    <div className='login_signup'>
      <div
        id='kt_body'
        className='header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed page-loading'
      >
        <div className='d-flex flex-column flex-root'>
          <div
            className='login login-5 login-signin-on d-flex flex-row-fluid'
            id='kt_login'
          >
            <div className='d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid'>
              <div className='login-form text-center text-white p-7 position-relative overflow-hidden'>
                <div className='d-flex flex-center mb-15'>
                  <Link to='/'>
                    <img src={Logo} className='max-h-120px' alt='' />
                  </Link>
                </div>
                <div className='login-signin'>
                  <div className='mb-18' style={{marginTop: '3rem'}}>
                    <h3 className='opacity-40 font-weight-normal'>Sign In</h3>
                    <p className='opacity-40'>
                      Enter your details to login to your account:
                    </p>
                  </div>
                  <form className='form' onSubmit={handleLogin}>
                    <div className='form-group'>
                      <input
                        className='form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8'
                        type='text'
                        placeholder='enter username'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div className='form-group'>
                      <input
                        className='form-control h-auto text-white bg-white-o-5 rounded-pill border-0 py-4 px-8'
                        type='password'
                        placeholder='enter password'
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                    {/* <div className="form-group d-flex flex-wrap justify-content-between align-items-center px-8 opacity-60">
                                      <div className="checkbox-inline">
                                          <label className="checkbox checkbox-outline checkbox-white text-white m-0">
                                              <input type="checkbox" name="remember" />
                                              <span></span>Remember me
                                          </label>
                                      </div>
                                      <Link to='/forgot-password' id="kt_login_forgot" className="text-white font-weight-bold">ForgetPassword ?</Link>
                                  </div> */}
                    <div className='form-group text-center mt-10'>
                      <button
                        id='kt_login_signin_submit'
                        className='btn btn-pill btn-primary opacity-90 px-15 py-3'
                      >
                        Sign In
                      </button>
                    </div>
                  </form>
                  <div className='mt-10'>
                    <span className='opacity-40 mr-4'>
                      Forgot your password?
                    </span>
                    <Link
                      to='/retieve'
                      className='text-white opacity-30 font-weight-normal'
                    >
                      CLick Here
                    </Link>
                  </div>
                  <div className='mt-2'>
                    <span className='opacity-40 mr-4'>
                      Don't have an account?
                    </span>
                    <Link
                      to='/signup'
                      className='text-white opacity-30 font-weight-normal'
                    >
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
