import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
// import PresImg from '../assets/img/list.jpg';
import PresImg from '../assets/img/pre_dummy.png';
import { Link, useParams } from 'react-router-dom';
import useFetch from '../useFetch';
import moment from 'moment';
import PatientId from '../components/Loading/PatientId';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const SinglePatients = () => {
  const { patient_id } = useParams();
  const { data: patient, isLoaging } = useFetch(
    `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/${patient_id}`
  );

  const { data: prescriptions } = useFetch(
    `${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription_by_patient/${patient_id}`
  );

  const idGen = (id) => {
    if (id < 10) {
      return '0000' + id;
    } else if (id < 100) {
      return '000' + id;
    } else if (id < 1000) {
      return '00' + id;
    } else if (id < 10000) {
      return '0' + id;
    } else {
      return id;
    }
  };
  
  useEffect(() => {
    console.log(prescriptions)
    
    return () => {
      
    }
  }, [prescriptions])
  
  
  const [ShowModal, setShowModal] = useState(false);
  const [IsCopyingTesxt, setIsCopyingTesxt] = useState(false)
  const [rx, setRx] = useState(null);
  
  const copyRxText = async () => {
    setIsCopyingTesxt(true);
    
    const doctor = JSON.parse(localStorage.getItem('user-info')).doctor

    const medicationPromise = await fetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/medication_by_prescription/${rx.id}`, { method: 'GET' });
    const medicationResult = await medicationPromise.json();

    // console.log(medicationResult);

    let text = rx ? `${moment(rx.created_at).format('DD-MM-YYYY')}\n\
${doctor.title} ${doctor.full_name}\n\
${doctor.bio ? doctor.bio + '\n' : ''}\
BMDC Reg. No. : ${doctor.bmdc_number}\n\
Address: ${doctor.address_thana ? doctor.address_thana + ', ' : ''}${doctor.address_district}\n\
\n\
Patient Name: ${patient ? patient.name : ''}\n\
Mobile: ${(patient && patient.mobile) ? patient.mobile : ''}\n\
Age : ${!patient ? '' : patient.age} ${!patient ? '' : patient.age_type + 's'}\n\
Sex: ${!patient ? '' : patient.gender}\n\
Investigation: ${rx.dx}\n\
CC : ${rx.symptoms} \n\
\n\
Rx \n\
${medicationResult.map((item, i) => `${i + 1}. ${item.medication_type} ${item.medication_name} ${item.strength} \n\
${item.medication_schedule_monring}+${item.medication_schedule_afternoon}+${item.medication_schedule_evening}		---${item.days}: Days \n\
${item.advice ? item.advice : ''}
\n` ).join('')}\
Advice: ${rx.advice}\n\
\n\
Next Appointment: ${moment(rx.followup_date).format('DD MMM YYYY')}\n\
${doctor.mobile ? ('Call For Appointment: ' + doctor.mobile) : ('Email For Appointment: ' + doctor.user.username)}\n\
\n\
Created with e-laj by Amarlab Ltd.\n\
${moment().format('DD MMM YYYY; hh:mm A')}` : `No Data found.`

    await navigator.clipboard.writeText(text);
    setIsCopyingTesxt(false)
    toast.success("Prescription texts has been copied, you can paste it anywhere ", {autoClose: 2000});

  }

  const copyUrl = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}/prescription/${rx.id}`);
    toast.success("Prescription URL has been copied, you can paste it anywhere ", {autoClose: 2000});
  }
  

  return (
    <div className='row myrx search patient_profile'>
      <div className='col-md-8'>
        <div className='card'>
          {isLoaging ? (
            <PatientId />
          ) : (
            <div className='card-body'>
              <div className='row mb-8'>
                {patient && (
                  <div className='col-md-12'>
                    <h2 className='mb-4'>
                      Patient ID: #{idGen(patient.token)}
                    </h2>
                    <div className='card' style={{ background: '#f7f7f7' }}>
                      <div className='card-body'>
                        <div className='d-flex flex-wrap align-items-start'>
                          <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                            <span className='text-dark-65 font-size-lg'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Name:
                              </span>{' '}
                              {patient.name}
                            </span>
                            <span className='text-dark-65 font-size-lg mt-2'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Age:
                              </span>{' '}
                              {patient.age ? (`${patient.age} ${patient.age_type}s`) : moment().diff(`${patient.dob}`, 'years')}
                            </span>
                            <span className='text-dark-65 font-size-lg mt-2'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Sex:
                              </span>{' '}
                              {patient.gender}
                            </span>
                            <span className='text-dark-65 font-size-lg mt-2'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Location:
                              </span>{' '}
                              {patient.address_thana},{' '}
                              {patient.address_district}
                            </span>
                            <span className='text-dark-65 font-size-lg mt-2'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Marital Status:
                              </span>{' '}
                              {patient.marital_status}
                            </span>
                            <span className='text-dark-65 font-size-lg mt-2'>
                              <span className='text-dark-75 font-weight-bolder'>
                                Occupation:
                              </span>{' '}
                              {patient.occupation}
                            </span>
                          </div>
                          <div className='symbol symbol-60 symbol-2by3 flex-shrink-0'>
                            <span className='icons'>
                              <Link to={`/patient/${patient.id}/edit`}>
                                <i style={{color: '#54b9a8'}} className='la la-edit edit' />
                              </Link>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {prescriptions && (
                <h5 className='my-4' style={{ fontSize: '18px' }}>
                  {prescriptions.length}+ Rx. Found
                </h5>
              )}
              <div className='row lists'>
                {prescriptions &&
                  prescriptions.map((prescription, i) => (
                    <div className='col-12' key={i}>
                      <div className='card card-custom card-stretch gutter-b'>
                        <div className='card-body p-6'>
                          <div className='d-flex flex-wrap align-items-center'>
                            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                              <span className='font-weight-bolder font-size-lg text-primary'>
                                #{prescription.token}
                              </span>
                              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                                10 Feb 2021, 10:35 PM
                              </span>
                              <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                                <span className='font-weight-bolder text-dark-75'>
                                  Symptoms:
                                </span>{' '}
                                {prescription.symptoms}
                              </span>
                              <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                                <span className='font-weight-bolder text-dark-75'>
                                  Dx:
                                </span>{' '}
                                {prescription.dx}
                              </span>
                              <span className='icons mt-3'>
                                <span className='mr-2 pointer'
                                onClick={()=>{
                                  setRx(prescription)
                                  setTimeout(() => {
                                    setShowModal(true)
                                  }, 1);
                                }}
                                >
                                  <i style={{color: '#54b9a8'}} className='la la-share-square-o' />
                                </span>
                                <Link to={`/prescription/${prescription.id}`}>
                                  <i style={{color: '#54b9a8'}} className='la la-angle-right' />
                                </Link>
                              </span>
                            </div>
                            <div className='symbol symbol-60 symbol-2by3 flex-shrink-0 shadow'>
                              <div
                                className='symbol-label'
                                style={{
                                  backgroundImage: `url(${PresImg})`,
                                  opacity: '1.0',
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  borderColor: '#7C839920'
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
      <>
        <Modal show={ShowModal} onHide={() => setShowModal(false)}>
          <Modal.Header className='mx-6' style={{padding: 0, margin: '1rem 1.25rem 0rem 1.25rem', position: 'relative'}} >
            <Modal.Title className='mb-2'>Share Your Rx.</Modal.Title>
            <div 
            onClick={() => setShowModal(false)}
            className="d-flex justify-content-center align-items-center" 
            style={{borderRadius: 15, width: '20px', height: '20px', color: '#7C8399C0', border: '1px solid #7C8399C0', fontSize: 18, cursor: 'pointer', position: 'absolute', right: '-5px', top: 0 }} >
              <span>&times;</span>
            </div>
          </Modal.Header>
          <Modal.Body className='m-2 d-flex flex-column'>
            <Button variant="info" className='mx-0 font-weight-normal h6 p-3 mb-3' onClick={copyRxText} >Copy Rx. in text</Button>
            <Button variant="primary" className='mx-0 font-weight-normal h6 p-3' onClick={copyUrl} >Copy Rx. url</Button>
          </Modal.Body>

        </Modal>
      </>
    </div>
  );
};

export default SinglePatients;
