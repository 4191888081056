import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar';

import './ResetPassword.css';

export class ResetPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      password: '',
      cpassword: '',
      loading: false,
    }
  }

  handleResetButton = (e) => {
    e.preventDefault();
    const { password, cpassword, } = this.state;
    const user_info = JSON.parse(localStorage.getItem('user-info'));
    const token = user_info.token;
    const user_id = user_info.user_id;

    // console.log(token);

    if (password || cpassword) {
      if (!password) {
        toast.error("Please fill password.", { autoClose: 3000 });
        return;
      }

      if (!cpassword) {
        toast.error("Please fill confirm password.", { autoClose: 3000 });
        return;
      }

      if (password !== cpassword) {
        toast.error("Password & Confirm password should be same.", { autoClose: 3000 });
        return;
      }

      this.setState({ loading: true });
      fetch(process.env.REACT_APP_AXIOS_BASE_URL + '/api/change-password/', {
        method: 'POST',
        body: JSON.stringify(
          {
            user_id: user_id,
            password: password
          }
        ),
        headers: {
          'Authorization': 'Token ' + token,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "OK") {
            this.setState({ password: '' });
            this.setState({ cpassword: '' });
            this.setState({ loading: false });
            toast.success("Signature has been removed successfully !", { autoClose: 3000 });
            // props.navigation.replace('DrawerNavigationRoutes');
          } else {
            toast.error("Password update failed !", { autoClose: 3000 });
          }

        })
        .catch((error) => {
          this.setState({ loading: false });
          console.error(error);
          toast.error("Password update failed !", { autoClose: 3000 });
        });

    }
    else {
      // this.setState({loading: !this.state.loading})
      toast.error("Fill password and confirm password !", { autoClose: 3000 });
    }
  }

  render() {
    return (
      <div className='row profile'>
        <div className='col-md-8'>
          <div className='card card-custom card-stretch'>
            <div className='card-header py-3'>
              <div className='card-title align-items-start flex-column'>
                <h3 className='card-label font-weight-bolder text-dark'>
                  Reset Password
                </h3>
                <span className='text-muted font-weight-bold font-size-sm mt-1'>
                  Reset your password
                </span>
              </div>
            </div>
            <div className='card-body'>
              <form onSubmit={e => this.handleResetButton(e)}>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    New Password
                  </label>
                  <div className="col-xl-7 col-lg-9">
                    <input
                      value={this.state.password}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      className="form-control form-control-lg form-control-solid "
                      type="text"
                      placeholder="New Password" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">
                    Confirm Password
                  </label>
                  <div className="col-xl-7 col-lg-9">
                    <input
                      value={this.state.cpassword}
                      onChange={(e) => this.setState({ cpassword: e.target.value })}
                      className="form-control form-control-lg form-control-solid "
                      type="text"
                      placeholder="Confirm Password" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className='col-xl-3 col-lg-3 col-form-label'></label>
                  <div className='col-lg-9 col-xl-7'>
                    <button type='submit' className='btn btn-primary'>
                      {this.state.loading &&
                        <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        className='mr-2'
                        style={{
                          width: '15px',
                          height: '15px',
                        }}
                      />
                      }
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <Sidebar />
        </div>
      </div>
    )
  }
}

export default ResetPassword;
