// import useFetch from '../useFetch';
import Skeleton from 'react-loading-skeleton';
import MedicineLoading from './Loading/MedicineLoading';
import MedicineItem from './MedicineItem';
// import useFetchRecursive from '../useFetchRecursive';
import { useEffect, useState } from 'react';

const Medicine = ({
  showSearch,
  searchResult,
  setSearchResult,
  searchSeeMore,
  setSearchSeeMore,
  searchVisible,
  setSearchVisible,
  setNextP,
  // handleSearchLoadMore,
}) => {
  // const {
  //   data: medicines,
  //   showSeeMore,
  //   handleLoadMore,
  //   visible,
  //   isLoaging,
  // } = useFetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/medicine/`);

  // const {
  //   data: medicines,
  //   showSeeMore,
  //   handleLoadMore,
  //   visible,
  //   isLoaging,
  // } = useFetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/medicine_limit/20`);

  // const {
  //   data: medicines,
  //   showSeeMore,
  //   handleLoadMore,
  //   visible,
  //   fetchData,
  //   isLoading,
  //   count,
  //   next
  // } = useFetchRecursive(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/v2/medicine/?limit=500`);

  
  const [medicines, setMedicines] = useState([]);
  const [showSeeMore, setShowSeeMore] = useState(true);
  const [visible, setVisible] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState('');
  
  
  const [searchCount, setSearchCount] = useState(0);
  const [searchNext, setSearchNext] = useState('');
  const [isFirstSearch, setIsFirstSearch] = useState(true);
  
  const fetchMedicine = async (url) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    .then(response => response.json())
      .then(response => {
        // console.log('medicines: ', response.results)
        setMedicines(response.results);
        setCount(response.count);
        setNext(response.next);
        setIsLoading(false);
      })
      .catch(err => {
        console.log('medicine error: ', err);
        setIsLoading(false);
      })
  }
  
  const handleLoadMore = async () => {
    if(visible >= medicines.length){
        if(next) await fetchMedicine(next);
        else setShowSeeMore(false);
    }
    
    setVisible(visible + 5);
    const postsLength = medicines.length;
    
    if (postsLength >= count) {
      setShowSeeMore(false);
    }
  };
  
  
  
  const handleFirstSearch = async() =>{
    fetch(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?search=Napa&limit=${count}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    .then(response => response.json())
      .then(response => {
        // console.log('medicines: ', response.results)
        setSearchResult(response.results);
        setSearchCount(response.count);
        setSearchNext(response.next);
        setIsLoading(false);
        setIsFirstSearch(false);
      })
      .catch(err => {
        console.log('medicine error: ', err);
        setIsFirstSearch(false);
        setIsLoading(false);
      })
  }

  const handleSearchLoadMoreMed = async () => {
    const medLength = searchResult.length;
    const nowShownig = searchVisible + 5;
    if (medLength <= nowShownig) {
      // console.log('here', searchNext, searchCount);
      if (isFirstSearch) await handleFirstSearch();
      else setSearchSeeMore(false);
    }
    setSearchVisible(searchVisible + 5);
  };


  // (() => {
  //   fetchMedicine(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=500`);
  // })();
  
  useEffect(() => {
    fetchMedicine(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=500`);
    // setNextP(next);
  }, [])
  
  // const handleSearchLoadMoreMed = async() => {
  //   const medLength = searchResult.length;
  //   const nowShownig = searchVisible + 5;
  //   if (medLength <= nowShownig) {
  //     if(next) await fetchMedicine(next);
  //     else setSearchSeeMore(false);
  //   }
  //   setSearchVisible(searchVisible + 5);
  // };
  
  return (
    <div>
      {isLoading ? (
        <div>
          <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
            <Skeleton width={230} />
          </h5>
          <div className='row lists'>
            <MedicineLoading />
            <MedicineLoading />
            <MedicineLoading />
            <MedicineLoading />
            <MedicineLoading />
            <div className='col-12'>
              <p className='text-center mb-0'>
                <span className='font-size-lg'>
                  <Skeleton width={70} />
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!showSearch ? (
            <div>
              {medicines && (
                <div>
                  <h5 className='my-4' style={{ fontSize: '18px' }}>
                    {count}+ Medicines Found
                  </h5>
                  <div className='row lists'>
                    {medicines
                      .slice(0, visible)
                      .map((medicine, i) => (
                      <MedicineItem medicine={medicine} key={i} />
                    ))}
                    {showSeeMore && (
                      <div className='col-12'>
                        <p className='text-center mb-0'>
                          <span
                            className='font-weight-bolder font-size-lg pointer text-primary'
                            onClick={handleLoadMore}
                          >
                            See More
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className='row lists'>
                {!searchResult.length && (
                  <div className='col-md-12'>
                    <h5 className='font-weight-bold'>
                      Sorry, no medicines found.
                    </h5>
                  </div>
                )}
                {searchResult.slice(0, searchVisible).map((medicine, i) => (
                  <MedicineItem medicine={medicine} key={i} />
                ))}
                {searchSeeMore && (
                  <div className='col-12'>
                    <p className='text-center mb-0'>
                      <span
                        className='font-weight-bolder font-size-lg pointer text-primary'
                        onClick={handleSearchLoadMoreMed}
                      >
                        See More
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Medicine;
