import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import LogoImg from '../../assets/img/E-Laj_Clrd2_landscape.png';
import SnehoImg from '../../assets/img/sneho.png';
import './PrescriptionPreviewStyle.css';

import html2canvas from 'html2canvas';
import SocialShareModal from './SocialShareModal';
import PrescriptionPreviewSkeleton from './PrescriptionPreviewSkeleton';

const PrescriptionPreviewPartner = () => {
  const { prescription_id } = useParams();
  const [Prescription, setPrescription] = useState(null);
  const [Doctor, setDoctor] = useState(null);
  const [Patient, setPatient] = useState(null);
  const [Medication, setMedication] = useState(null);
  const [Signature, setSignature] = useState('');
  const [PrescriptionLogo, setPrescriptionLogo] = useState(SnehoImg);
  
  const [ShareModalVisible, setShareModalVisible] = useState(false);
  const [DataLoading, setDataLoading] = useState(true);
  const [showDiv, setShowDiv] = useState(true);
  // const prescriptionURL = `${process.env.REACT_APP_AXIOS_BASE_URL}/api/rx/${prescription_id}`;
  const prescriptionURL = window.location.href;
  // const user_info = JSON.parse(localStorage.getItem('user-info'));
  // const token = user_info.token;
  const [isLoading, setIsLoading] = useState(true);

  const fetchMedication = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/medication_by_prescription/${prescription_id}`
      )
      .then((response) => {
        const data = response.data;
        setMedication(data);
      })
      .catch((error) => {
        console.log(error);
        setDataLoading(false);
      });
  };

  const fetchSignature = (doctor_id) => {
    // console.log(user_info);
    axios
      .get(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/v2/doctor-setting/?doctor=${doctor_id}`
      )
      .then((response) => {
        // console.log(response)
        const data = response.data.results.length
          ? response.data.results
          : response.data;
        setSignature(data[0].signature);
      })
      .catch((error) => {
        console.log(error);
        setDataLoading(false);
      });
  };



  const fetchPrescriptionLogo = (doctor_id) => {
    // console.log(user_info);
    axios
      .get(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/v2/doctor-setting-prescription/?doctor=${doctor_id}`
      )
      .then((response) => {
        // console.log(response)
        const data = response.data.results.length
          ? response.data.results
          : response.data;
        setPrescriptionLogo(data[0].prescription_logo);
      })
      .catch((error) => {
        console.log(error);
        setDataLoading(false);
      });
  };




  const SentenceCase = (Sentence) => {
    return Sentence.charAt(0).toUpperCase() + Sentence.slice(1);
  };

  // const handleDownload = () => {
  //     window.scrollTo(0, 0);
  //     html2canvas(document.querySelector('#prescription_preview')).then(
  //         (canvas) => {
  //             // save as png
  //             let a = document.createElement('a');
  //             a.href = canvas.toDataURL('image/png');
  //             a.download = `prescription_${prescription_id}.png`;
  //             a.click();
  //             // delete a;

  //             // save as pdf
  //             // window.jsPDF = window.jspdf.jsPDF;
  //             // let dataImg = canvas.toDataURL('image/png');
  //             // const doc = new jsPDF();
  //             // const imgProps = doc.getImageProperties(dataImg);
  //             // const pdfWidth = doc.internal.pageSize.getWidth() - 3;
  //             // const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //             // doc.addImage(dataImg, 'PNG', 0, 3.5, pdfWidth, pdfHeight);
  //             // doc.save(`prescription_${id}.pdf`);
  //         }
  //     );
  // }

  // const newDownloadPrintableDocument = (domElement) => {
  //   let p = document.createElement('div');
  //   // p.style.display = 'none'
  //   let domElement2 = domElement.cloneNode(true);
  //   domElement2.setAttribute('id', 'new_rx');
  //   domElement2.style.height = '1080px';
  //   domElement2.style.minWidth = '764px';
  //   domElement2.style.maxWidth = '800px';
  //   domElement2.style.display = 'flex';

  //   domElement2.querySelector(
  //     '.prescription-details-container .details-leftside'
  //   ).style.maxWidth = '250px';
  //   domElement2.querySelector(
  //     '.prescription-details-container .details-leftside'
  //   ).style.minWidth = '250px';
  //   for (const element of domElement2.querySelectorAll(
  //     '.patient-wrapper .patient-part'
  //   )) {
  //     element.style.fontSize = '14px';
  //   }
  //   for (const element of domElement2.querySelectorAll(
  //     '.prescription-item-title'
  //   )) {
  //     element.style.fontSize = '16px';
  //   }
  //   domElement2.querySelector('#signature-sec').style.maxHeight = 'none';
  //   domElement2.querySelector('#signature-sec').style.paddingRight = '5px';
  //   domElement2.querySelector('#poweredby-sec-left').style.maxHeight = '90px';
  //   domElement2.querySelector(
  //     '#poweredby-sec-left #powred-by-amarlab'
  //   ).style.fontSize = '13px';

  //   p.appendChild(domElement2);
  //   document.body.appendChild(p);

  //   html2canvas(domElement2).then((canvas) => {
  //     // document.body.appendChild(canvas);
  //     let a = document.createElement('a');
  //     a.href = canvas.toDataURL('image/png');
  //     a.download = `prescription_${prescription_id}.png`;
  //     a.click();
  //   });

  //   // console.log(p);
  //   p.remove();
  // };


const newDownloadPrintableDocument = () => {
  if (!isLoading) {
    // Select the target element (prescription-container) that you want to download
    const targetElement = document.getElementById('prescription_preview');

    // Use html2canvas to capture the target element as a canvas
    html2canvas(targetElement)
      .then((canvas) => {
        // Convert the canvas to a data URL representing the PNG file
        const dataUrl = canvas.toDataURL('image/png');

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'prescription.png';

        // Simulate a click on the link element to start the download
        link.click();
      })
      .catch((error) => {
        console.error('Error generating printable document:', error);
      });
  }
};

const handleDownload = async () => {
  const printContent = document.getElementById('prescription_preview');

  // Use html2canvas to convert the content to an image
  html2canvas(printContent)
    .then((canvas) => {
      // Convert the canvas to a data URL
      const dataURL = canvas.toDataURL('image/png');

      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = dataURL;
      anchor.download = 'prescription.png';

      // Simulate a click event to trigger the download
      anchor.click();
    })
    .catch((error) => {
      console.log('Error:', error);
    });
};

  const prevPrintDocument = (domElement) => {
    html2canvas(domElement).then((canvas) => {
      // document.body.appendChild(canvas);
      let a = document.createElement('a');
      a.href = canvas.toDataURL('image/png');
      a.download = `prescription_${prescription_id}.png`;
      a.click();
    });
  };

  const setCopyURL = async () => {
    // var copyText = document.getElementById("urlInput");

    // /* Select the text field */
    // copyText.select();
    // copyText.setSelectionRange(0, 99999); /* For mobile devices */
    // console.log('--',copyText.value);

    /* Copy the text inside the text field */
    // navigator.clipboard.writeText(copyText.value);
    await navigator.clipboard.writeText(window.location.href);

    /* Alert the copied text */
    alert('Prescription URL has been copied, you can past it anywhere ');
  };


  const setForPrint = async () => {
    await navigator.clipboard.writeText(window.location.href);
    const printContent = document.getElementById('prescription_preview').innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContents;
  };


  const handleLogoLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setDataLoading(true);
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/${prescription_id}`
      )
      .then(async (response) => {
        const data = response.data;
        console.log(data);
        setPrescription(data);
        fetchPrescriptionLogo(data.doctor);
        axios
          .get(
            `${process.env.REACT_APP_AXIOS_BASE_URL}/api/doctor/${data.doctor}`
          )
          .then((response) => {
            setDoctor(response.data);
            fetchSignature(data.doctor);
            fetchPrescriptionLogo(data.doctor);
          });

        await axios
          .get(
            `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/${data.patient}`
          )
          .then((response) => {
            setPatient(response.data);
          });

        await fetchMedication();
        setDataLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setDataLoading(false);
      });



    const urlParams = new URLSearchParams(window.location.search);
    const queryStringExists = urlParams.has('sneho');

    if (queryStringExists) {
      setShowDiv(true);
    }



  }, []);

  const canvasRef = useRef(); //useref ta import kora lagbe

  if (DataLoading) return <PrescriptionPreviewSkeleton />;
  else
    return (
      <div className='prescription-page'>
        {true && (
          <SocialShareModal
            modalVisible={ShareModalVisible}
            setModalVisible={setShareModalVisible}
          />
        )}
        <div
          ref={canvasRef}
          className='prescription-container'
          id='prescription_preview'
        >
          <div className='header-wrapper'>
            <div className='header-partL' >
              {/* <div className='prescription-item-title'>Powered By.</div> */}

                <div className='logo-container'>
                  <div className='logo-wrapper'>
                    <img className='brand-logo new' style={{ marginLeft: '0px' }} src={PrescriptionLogo} alt='' />
                  </div>
                </div>


            </div>
            {/* <div className='header-partM'></div> */}
            <div className='header-partR'>
              {Doctor && (
                <div className='header-textR'>
                  <div className='doc-name'>
                    {Doctor.title + ' ' + Doctor.full_name}
                  </div>
                  <div className='doc-speciality'>{Doctor.speaciality}</div>
                  {Doctor.bio && (
                    <div className='doc-professional-bio'>{Doctor.bio}</div>
                  )}
                  <div className='doc-contact-section'>
                    {Doctor.mobile_number && (
                      <div className='doc-mobile'>{Doctor.mobile_number}</div>
                    )}
                    ,
                    {Doctor.email && (
                      <div className='doc-email'>{Doctor.email}</div>
                    )}
                  </div>
                  {Doctor.address_district && (
                    <div className='doc-address'>
                      {Doctor.address_thana + ', '} {Doctor.address_district}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='patient-wrapper'>
            <div className='patient-details'>
              <div className='patient-part'>
                Name: {Patient && <span>{Patient.name}</span>}
              </div>
              <div className='patient-part'>
                Age:{' '}
                {Patient && (
                  <span>
                    {Patient.age} {Patient.age_type}s
                  </span>
                )}
              </div>
              <div className='patient-part'>
                Rx. ID: {Prescription && <span>{Prescription.id}</span>}
              </div>
              <div className='patiePrescriptionnt-part'>
                Created On:{' '}
                {Prescription && (
                  <span>
                    {moment(Prescription.created_at).format(
                      'DD MMM YYYY; HH:MM A'
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='prescription-details-container'>
            <div className='details-leftside'>
              <div className='prescription-item'>
                <div className='prescription-item-title'> C/C.</div>
                <div className='prescription-item-content'>
                  {!Prescription ? '' : Prescription.symptoms}
                </div>
              </div>
              <div className='prescription-item'>
                <div className='prescription-item-title'>History.</div>
                <div className='prescription-item-content'>
                  {!Prescription ? '' : Prescription.signs}
                </div>
              </div>
              <div className='prescription-item'>
                <div className='prescription-item-title'> O/E.</div>
                <div className='prescription-item-content'>
                  {!Prescription ? '' : Prescription.investigations}
                </div>
              </div>
              <div className='prescription-item'>
                <div className='prescription-item-title'> Investigations.</div>
                <div
                  className='prescription-item-content'
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                  }}
                >
                  {!Prescription
                    ? ''
                    : Prescription.dx.split(',').map((item, index) => (
                        <div key={index} className='investigation-wrapper'>
                          * {item}
                        </div>
                      ))}
                </div>
              </div>
              <div className='prescription-item' id='poweredby-sec-left'>
                <div
                  id='powerdby-logo-wrapper-left'
                  style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                >
                  <div className='logo-container'>
                    <div className='logo-wrapper'>
                      <img style={{ marginLeft: '0px' }} src={LogoImg} alt='' />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingRight: '10px',
                    }}
                  >
                    {' '}
                    -{' '}
                  </div>

                  <div
                    className='prescription-item-title'
                    id='powred-by-amarlab'
                  >
                    Powered by Amarlab
                  </div>
                </div>
              </div>
            </div>

            <div className='details-divider'></div>

            <div className='details-rightside'>
              <div className='prescription-item'>
                <div className='prescription-item-title'>Rx.</div>
                <div className='prescription-item-content' id='rx-content'>
                  {!Medication
                    ? ''
                    : Medication.map((item, index) => (
                        <div key={'' + index} className='medication-wrapper'>
                          <div className='medication-brief'>
                            <div className='medicine-name'>
                              {item.medication_name},
                            </div>
                            {item.medication_type !== 'Fleet Enema' && (
                              <div className='medication-generic-strength'>
                                <div className='medicine-strength'>
                                  {item.strength}
                                </div>
                              </div>
                            )}
                            <div className='medicine-type'>
                              ({item.medication_type})
                            </div>
                          </div>
                          {/* {item.medication_type !== 'Fleet Enema' && <div className="medication-generic-strength">
                                                <div className="medicine-generic">{item.genetic}</div>
                                                <div className="medicine-strength">{item.strength}</div>
                                            </div>} */}
                          {(item.medication_type === 'Tablet/Capsule' ||
                            item.medication_type === 'Tablet' ||  item.medication_type === 'Capsule') && (
                            <div className='medication-schedule'>
                              <span>
                                {item.medication_schedule_monring}{' + '} 
                                {item.medication_schedule_afternoon}{' + '} 
                                {/*{item.medication_schedule_evening}*/}
                                 {item.medication_schedule_before_evening}
                                {/*{item.medication_schedule_night} */}


                              </span>
                            </div>
                          )}
                          <div className='medication-does-meal-days'>
                            {item.medication_type !== 'Tablet/Capsule' &&
                              item.medication_type !== 'Tablet' &&  item.medication_type !== 'Capsule' && (
                                <div className='medication-doseDrop'>
                                  <span>{item.dose_drops_time_puff},</span>
                                </div>
                              )}
                            {(item.medication_type === 'Tablet/Capsule' ||
                              item.medication_type === 'Tablet' || item.medication_type === 'Capsule' ||
                              item.medication_type === 'Syrup') && (
                              <div className='medication-mealtime'>
                                <span>{item.medication_with_meal},</span>
                              </div>
                            )}
                            {item.medication_type !== 'Nebulization' && (
                              <div className='medication-days'>
                                <span>{item.days} Days</span>
                              </div>
                            )}
                          </div>
                          {item.medication_type === 'Nebulization' && (
                            <div className='medication-mixture'>
                              <span>{item.mixture_details}</span>
                            </div>
                          )}
                          {item.advice && (
                            <div className='medication-advice'>
                              Note: <span> {item.advice}</span>
                            </div>
                          )}
                        </div>
                      ))}
                </div>
              </div>
              <div className='prescription-item'>
                <div className='prescription-item-title'>Advice.</div>
                <div className='prescription-item-content'>
                  {!Prescription ? '' : Prescription.advice}
                </div>
              </div>
              <div className='prescription-item' style={{ marginBottom: 0 }}>
                <div className='prescription-item-title'>Followup Date.</div>
                <div className='prescription-item-content'>
                  {/* {Prescription &&
                  Prescription.followup_date &&
                  moment(Prescription.followup_date)
                    .format('DD MMM YYYY')
                    .includes('Invalid')
                    ? moment(Prescription.followup_date).format('DD MMM YYYY')
                    : ' '} */}
                    {Prescription &&
                    !!Prescription.followup_date &&
                    !(moment(Prescription.followup_date)
                      .format('DD MMM YYYY')
                      .includes('Invalid'))
                      ? moment(Prescription.followup_date).format('DD MMM YYYY')
                      : ' '}
                </div>
              </div>
              <div className='prescription-item' id='signature-sec'>
                {/* <div
                  style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                >
                  <div className='prescription-item-title'>Powered By.</div>
                  <div className='logo-container'>
                    <div className='logo-wrapper'>
                      <img style={{ marginLeft: '0px' }} src={LogoImg} alt='' />
                    </div> */}
                {/* <div className="logo-title">e-laj</div> */}
                {/* </div>
                </div> */}
                <div
                  id='powerdby-logo-wrapper-right'
                  style={{ display: 'none', alignItems: 'center', flexGrow: 1 }}
                >
                  <div className='logo-container'>
                    <div className='logo-wrapper'>
                      <img style={{ marginLeft: '0px' }} src={LogoImg} alt='' />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingRight: '5px',
                    }}
                  >
                    -
                  </div>
                  <div
                    className='prescription-item-title'
                    id='powred-by-amarlab'
                  >
                    Powered by Amarlab
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexGrow: 1,
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className='signature-wrapper'>
                    <div className='signature-image-wrapper'>
                      {!!Signature && (
                        <img
                          onLoad={handleLogoLoad}
                          src={Signature}
                          alt='alteration'
                          className='signature-image'
                        />
                      )}
                    </div>
                    <div className='signature-dash'>
                      ------------------------------
                    </div>
                    <div className='signature-footer'>Signature</div>
                  </div>
                </div>
              </div>
              {/* <div className='signature-container'> */}
              {/* <div className="signature-wrapper">
                                <div className="signature-image-wrapper">
                                    {!!Signature && <img src={Signature} alt="alteration" className='signature-image' />}
                                </div>
                                <div className="signature-dash">
                                    ------------------------------
                                </div>
                                <div className="signature-footer">
                                    Signature
                                </div>
                            </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* <><input type="text" id="urlInput" value={prescriptionURL} style={{display: 'none'}}></input></> */}
        <div className='button-container'>
          <div className='copy-container' onClick={() => setCopyURL()}>
            <div className='copy-wrapper'>Copy Rx. Url</div>
          </div>
          <div
            className='share-container'
            onClick={() => setShareModalVisible(true)}
          >
            <div className='share-wrapper'>Share</div>
          </div>

          <div
            className='share-container'
            onClick={() => setForPrint()}
          >
            <div className='share-wrapper'>Print</div>
          </div>

          <div
            className='download-container'
            onClick={() => handleDownload()}
          >
            <div className='download-wrapper' >Download</div>
          </div>
        </div>
      </div>
    );
};

export default PrescriptionPreviewPartner;
