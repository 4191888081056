import { Link } from 'react-router-dom';

const SignlePatient = ({ patient }) => {
  const idGen = (id) => {
    if (id < 10) {
      return '0000' + id;
    } else if (id < 100) {
      return '000' + id;
    } else if (id < 1000) {
      return '00' + id;
    } else if (id < 10000) {
      return '0' + id;
    } else {
      return id;
    }
  };

  return (
    <div className='col-12' key={patient.id}>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card-body p-6'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-75 font-weight-bolder font-size-lg'>
                {patient.name}
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                10 Feb 2021, 10:35 PM
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                {patient.address_thana}, {patient.address_district}
              </span>
            </div>
            <div className='d-flex align-self-start py-lg-0 py-2'>
              <div className='d-flex flex-column text-right'>
                <span className='text-primary font-weight-bolder font-size-h4'>
                  #{idGen(patient.token)}
                </span>
              </div>
            </div>
          </div>
          <div className='d-flex flex-wrap align-items-end'>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-50 font-weight-bolder'>
                <span className='text-dark-75'>7 Rx.</span> Found
              </span>
            </div>
            <div className='symbol symbol-60 symbol-2by3 flex-shrink-0'>
              <span className='icons'>
                <Link to={`/patient/${patient.id}`}>
                  <i className='la la-angle-right' />
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignlePatient;
