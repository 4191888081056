import React, { Component } from 'react'
import logo from '../assets/img/logos/elajico_tr.png'

import 'bootstrap/dist/css/bootstrap.css';

export class LandingPage extends Component {
  render() {
    return (
      <div className='langing-page'>
        <div className="" style={{}}>
          <nav class="shadow-sm sticky-top navbar navbar-expand-lg navbar-light">
            <div class="container main-con">
              <span class="navbar-brand">
                <a class="d-flex align-items-center" href="/">
                  <img src={logo} className='mh-100' alt="" style={{height: 70,}} />
                  <p class="mb-0 text-primary">E-laj</p></a>
              </span>
              
              {/* for mobile version */}
              {/* <button type="button" aria-label="Toggle navigation" class="navbar-toggler collapsed">
                <span class="navbar-toggler-icon"></span>
              </button> */}
              
              <div class="navbar-collapse collapse">
                <div class="mr-auto left navbar-nav">
                  <a class="nav-link first" href="/diagnostic">Item 1</a>
                  <a class="nav-link" href="/packages">Item 2</a>
                </div>
                <div class="ml-auto right navbar-nav">
                  <div class="buttons">
                    <a class="btn btn-light login mr-2" href="/login">Login</a>
                    <a class="btn btn-primary signup" href="/signup">Signup</a>
                  </div>
                  <div class="cart desktop">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
              
              {/* for mobile version */}
              {/* <section class="user-tab-mbl ">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="details">
                        <div class="buttons">
                          <button class="btn btn-primary btn-block signup">Signup</button>
                          <button class="btn btn-primary btn-block login">Login</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
              <img src="" alt="" class="polygon-icon " />
            </div>
          </nav>
        </div>
        
        <div className="mainbody" style={{height: '120vh'}}>
          
        </div>
      </div>
    )
  }
}

export default LandingPage
