import Sidebar from '../components/Sidebar';
import { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import PresMyRx from '../components/Loading/PresMyRx';
import SingleRxItem from '../components/sub/SingleRxItem';

const MyRx = () => {
  const [allRx, setAllRx] = useState([]);
  const [toadyRx, setTodayRx] = useState([]);
  const [oldRx, setOldRx] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [today] = useState(moment().format('DD MMM YYYY'));
  const [search, setSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const [visibleOld, setVisibleOld] = useState(5);
  const [showMoreOld, setShowMoreOld] = useState(true);

  const [visibleAll, setVisibleAll] = useState(5);
  const [showMoreAll, setShowMoreAll] = useState(true);
  

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/`)
      .then((response) => {
        // console.log(response.data);
        setAllRx(response.data);
        const newArr = response.data.map((prescription) => {
          return {
            ...prescription,
            checkdate: moment(prescription.created_at).format('DD MMM YYYY'),
          };
        });
        const todayPres = newArr.filter((prescription) => {
          return prescription.checkdate === today;
        });
        const notTodayPres = newArr.filter((prescription) => {
          return prescription.checkdate !== today;
        });
        // console.log(notTodayPres.length);
        setTodayRx(todayPres);
        setOldRx(notTodayPres);
        setIsLoading(false);
        if (notTodayPres.length < 6) {
          setShowMoreOld(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filterRx = allRx.filter((rx) => {
      return (
        rx.patient && (rx.patient.mobile
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        rx.patient.name.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    });
    setSearchResults(filterRx);
    if (e.target.value.length) {
      // console.log(filterRx.length);
      if (filterRx.length < 6) {
        setShowMoreAll(false);
      }
      setShowSearch(true);
      setVisibleOld(5);
      // console.log('Search Result');
    } else {
      setShowSearch(false);
      setVisibleAll(5);
      setShowMoreAll(true);
      if (5 < oldRx.length) {
        setShowMoreOld(true);
      }
      // console.log('Main Result');
    }
  };

  const handleOldMore = () => {
    setVisibleOld(visibleOld + 5);
    const rxLength = oldRx.length;
    const nowShowing = visibleOld + 5;
    if (rxLength <= nowShowing) {
      setShowMoreOld(false);
    }
  };

  const handleAllMore = () => {
    setVisibleAll(visibleAll + 5);
    const rxLength = allRx.length;
    const nowShowing = visibleAll + 5;
    if (rxLength <= nowShowing) {
      setShowMoreAll(false);
    }
  };

  return (
    <div className='row myrx'>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder='Search Rx. (Name, ID or Mobile Number)'
              value={search}
              onChange={handleSearch}
            />
            {!allRx.length && (
              <h5 className='mb-0 mt-8 font-weight-bold'>
                You haven't created a prescription yet.
              </h5>
            )}
            {showSearch ? (
              <div className='row lists mb-4 mt-8'>
                {searchResults.slice(0, visibleAll).map((rx) => (
                  <SingleRxItem rx={rx} key={rx.id} />
                ))}
                {showMoreAll && (
                  <div className='col-12'>
                    <p className='text-center mb-0'>
                      <span
                        className='font-weight-bolder font-size-lg pointer text-primary'
                        onClick={handleAllMore}
                      >
                        See More
                      </span>
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {!isLoading ? (
                  <div>
                    {toadyRx.length !== 0 && (
                      <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
                        Today, {today}
                      </h5>
                    )}
                    {toadyRx.length !== 0 && (
                      <div className='row lists mb-4'>
                        {toadyRx.map((rx) => (
                          <SingleRxItem rx={rx} key={rx.id} />
                        ))}
                      </div>
                    )}
                    {oldRx.length !== 0 && toadyRx.length !== 0 && (
                      <h5 className='mb-4' style={{ fontSize: '18px' }}>
                        Later on Today
                      </h5>
                    )}
                    {oldRx.length !== 0 && (
                      <div
                        className={`${
                          toadyRx.length ? '' : 'mt-8'
                        } row lists mb-4`}
                      >
                        {oldRx.slice(0, visibleOld).map((rx) => (
                          // <SingleRxItem rx={rx} />
                          <SingleRxItem rx={rx} key={rx.id} />
                        ))}
                        {showMoreOld && (
                          <div className='col-12'>
                            <p className='text-center mb-0'>
                              <span
                                className='font-weight-bolder font-size-lg pointer text-primary'
                                onClick={handleOldMore}
                              >
                                See More
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
                      Today, {today}
                    </h5>
                    <div className='row lists mb-4'>
                      <PresMyRx />
                    </div>
                    <h5 className='mb-4' style={{ fontSize: '18px' }}>
                      Later on Today
                    </h5>
                    <div className='row lists'>
                      <PresMyRx />
                      <PresMyRx />
                      <PresMyRx />
                      <PresMyRx />
                      <PresMyRx />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
      
    </div>
  );
};

export default MyRx;
