import AsyncCreatableSelect from 'react-select/async-creatable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const MedicineList = ({
  setMedName,
  setMedid,
  setMediStrength,
  // setMediGenetic,
  medListItems,
}) => {
  const { logout } = useAuth();
  const [medicines, setMedicines] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionValue, setOptionValue] = useState('');

  const fetchMedicine = () => {
    axios
      .get(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=20`)
      .then((resp) => {
        const newArray = resp.data.results.map((medicine) => {
          // console.log(medicine);
          // const name = medicine.name.split('_');
          return {
            // id: medicine.id,
            // name: name[0].trim(),
            // strength: name[1].trim(),
            // genetic: name[2].trim(),
            ...medicine,
            value: medicine.name,
            label: `${medicine.name} — ${medicine.generic} — ${medicine.strength} — ${medicine.form}`,
            // label: medicine.name,
          };
        });
        setOptions(newArray);
        setMedicines(newArray);
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      });
  }

  useEffect(() => {
    if (!medListItems) {
      console.log('medicine');
      fetchMedicine();
    } else {
      setOptions(medListItems);
      setMedicines(medListItems);
    }
  }, []);

  const filterMedicine = async(inputValue) => {
    // return medicines.filter((i) =>
    //   i.label.toLowerCase().includes(inputValue.toLowerCase())
    // );
    const respPromise = await fetch(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=20&search=${inputValue ? inputValue : ''}`, { method: 'GET' })
      .catch((error) => {
        console.log(error);
      });
      const resp = await respPromise.json();
      const newArray = resp.results.map((medicine) => {
        return {
          ...medicine,
          value: medicine.name,
          label: `${medicine.name} — ${medicine.generic} — ${medicine.strength} — ${medicine.form}`,
        };
      });
      console.log(resp.results.length);
      // setOptions(newArray);
      // setMedicines(newArray);
      return newArray;
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterMedicine(inputValue));
      }, 1000);
    });

  const handleMedName = (e) => {
    // console.log(e);
    setMedName(e.value);
    setOptionValue(e.value);
    setMedid(e.id);
    setMediStrength(e.strength);
    // setMediGenetic(e.generic);
    console.log(optionValue);
  };

  const style = {
    control: (base) => ({
      ...base,
      boxShadow: '0 !important',
      minHeight: '46px !important',
      border: '1px solid #e4e6ef !important',
      // '&:hover': {
      //   border: '0 !important',
      // },
    }),
  };

  return (
    <AsyncCreatableSelect
      // value={optionValue}
      // defaultOptions={optionValue}
      styles={style}
      loadOptions={promiseOptions}
      defaultOptions={options}
      onChange={handleMedName}
      // onInputChange={handleInputChange}
      placeholder={
        <p
          className='mb-0'
          style={{
            fontSize: '1.08rem',
            color: '#c3c3cd',
          }}
        >
          Medicine Name
        </p>
      }
    />
  );
};

export default MedicineList;
