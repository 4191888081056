import { useState } from 'react';
import { Link } from 'react-router-dom';
import PresImg from '../../assets/img/pre_dummy.png';
import moment from 'moment';

import { Modal, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';

const SingleRxItem = ({ rx }) => {
  const [ShowModal, setShowModal] = useState(false);
  const [IsCopyingTesxt, setIsCopyingTesxt] = useState(false)

  const copyRxText = async () => {
    setIsCopyingTesxt(true);

    const medicationPromise = await fetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/medication_by_prescription/${rx.id}`, { method: 'GET' });
    const medicationResult = await medicationPromise.json();

    // console.log(medicationResult);

    let text = rx ? `${moment(rx.created_at).format('DD-MM-YYYY')}\n\
${rx.doctor.title} ${rx.doctor.full_name}\n\
${rx.doctor.bio ? rx.doctor.bio + '\n' : ''}\
BMDC Reg. No. : ${rx.doctor.bmdc_number}\n\
Address: ${rx.doctor.address_thana ? rx.doctor.address_thana + ', ' : ''}${rx.doctor.address_district}\n\
\n\
Patient Name: ${rx.patient ? rx.patient.name : ''}\n\
Mobile: ${(rx.patient && rx.patient.mobile) ? rx.patient.mobile : ''}\n\
Age : ${!rx.patient ? '' : rx.patient.age} ${!rx.patient ? '' : rx.patient.age_type + 's'}\n\
Sex: ${!rx.patient ? '' : rx.patient.gender}\n\
Investigation: ${rx.dx}\n\
CC : ${rx.symptoms} \n\
\n\
Rx \n\
${medicationResult.map((item, i) => `${i + 1}. ${item.medication_type} ${item.medication_name} ${item.strength} \n\
${item.medication_schedule_monring}+${item.medication_schedule_afternoon}+${item.medication_schedule_evening}		---${item.days}: Days \n\
${item.advice ? item.advice : ''}
\n` ).join('')}\
Advice: ${rx.advice}\n\
\n\
Next Appointment: ${moment(rx.followup_date).format('DD MMM YYYY')}\n\
${rx.doctor.mobile ? ('Call For Appointment: ' + rx.doctor.mobile) : ('Email For Appointment: ' + rx.doctor.user.username)}\n\
\n\
Created with e-laj by Amarlab Ltd.\n\
${moment().format('DD MMM YYYY; hh:mm A')}` : `No Data found.`

    await navigator.clipboard.writeText(text);
    setIsCopyingTesxt(false)
    toast.success("Prescription texts has been copied, you can paste it anywhere ", { autoClose: 2000 });

  }

  const copyUrl = async () => {
    await navigator.clipboard.writeText(`${window.location.origin}/prescription/${rx.id}`);
    toast.success("Prescription URL has been copied, you can paste it anywhere ", { autoClose: 2000 });
  }

  return (
    <>
      <div className='col-12' key={rx.id}>
        <div className='card card-custom card-stretch gutter-b'>
          <div className='card-body p-6'>
            <div className='d-flex flex-wrap align-items-center'>
              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                <span className='text-dark-75 font-weight-bolder font-size-lg'>
                  {rx.patient ? rx.patient.name : ''}
                </span>
                <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                  Created at: &nbsp;
                  <span className='text-dark font-weight-bold'>
                    {moment(rx.created_at).format('DD MMM YYYY, hh:mm A')}
                  </span>
                </span>
                <span className='icons mt-3'>
                  <span className='mr-2 pointer' onClick={() => setShowModal(true)}>
                    <i className='la la-share-square-o' />
                  </span>
                  <Link to={`/prescription/${rx.id}`}>
                    <i className='la la-angle-right' />
                  </Link>
                </span>
              </div>
              <div
                className='symbol symbol-60 symbol-2by3 flex-shrink-0'
                style={{ position: 'relative' }}
              >
                <div
                  className='symbol-label shadow-lg'
                  style={{
                    backgroundImage: `url(${PresImg})`,
                    opacity: '0.7',
                    overflow: 'hidden',
                  }}
                >
                  <img src={PresImg} alt='Prescription Preview' style={{ height: '100%', width: '100%' }} />
                </div>
                <span
                  className='text-dark-75 font-weight-bolder font-size-h4'
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translate(-50%, 0)',
                  }}
                >
                  #{rx.token}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <Modal show={ShowModal} onHide={() => setShowModal(false)}>
          <Modal.Header className='mx-6' style={{ padding: 0, margin: '1rem 1.25rem 0rem 1.25rem', position: 'relative' }} >
            <Modal.Title className='mb-2'>Share Your Rx.</Modal.Title>
            <div
              onClick={() => setShowModal(false)}
              className="d-flex justify-content-center align-items-center"
              style={{ borderRadius: 15, width: '20px', height: '20px', color: '#7C8399C0', border: '1px solid #7C8399C0', fontSize: 18, cursor: 'pointer', position: 'absolute', right: '-5px', top: 0 }} >
              <span>&times;</span>
            </div>
          </Modal.Header>
          <Modal.Body className='m-2 d-flex flex-column'>
            <Button variant="info" className='mx-0 font-weight-normal h6 p-3 mb-3 d-flex px-5' onClick={copyRxText} >
              <span className='flex-grow-1' >Copy Rx. in text</span>
              <Spinner animation="border" size="sm" role="status" aria-hidden="true" style={{ borderWidth: '2px', marginTop: 4}} />
            </Button>

            <Button variant="primary" className='mx-0 font-weight-normal h6 p-3' onClick={copyUrl} >Copy Rx. url</Button>
          </Modal.Body>

        </Modal>
      </>
    </>
  );
};

export default SingleRxItem;
