import Sidebar from '../components/Sidebar';

const Statistics = () => {
  return (
    <div className='row'>
      <div className='col-md-8'>
        <h1>This is Statistics page.</h1>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Statistics;
