import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import useFetch from '../useFetch';
import Moment from 'react-moment';
import { useEffect } from 'react';
import HomeMyRx from '../components/Loading/HomeMyRx';
import { BsCheckCircle } from 'react-icons/bs'

const Home = () => {
  const { currentUser } = useAuth();
  const {
    data: prescriptions,
    showSeeMore,
    handleLoadMore,
    visible,
    isLoaging,
  } = useFetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/`);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className='row homepage'>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            <div className='mb-3' style={{display: 'flex', alignItems: 'center'}}>
              <h5 className='mb-0 mr-3 font-weight-normal'>
                Hey, {currentUser.doctor.title} {currentUser.doctor.full_name}
              </h5>
              <div style={{ backgroundColor: currentUser.doctor.bmdc_number ? '#6db959d0' : '#99020290', color: '#fff', fontWeight: 600, display: 'flex', alignItems: 'center', padding: '2px 5px', borderRadius: '25px', fontSize: 10 }}>
                {currentUser.doctor.bmdc_number && <BsCheckCircle size={15} style={{ marginRight: 5 }} />}
                {currentUser.doctor.bmdc_number ? `Verified Doctor` : 'Please provide BMDC number to verify.'}
              </div>
            </div>
            <h2 className='mb-0'>Write Prescription for your patients</h2>
            <div className='row buttons'>
              <div className='col-md-4'>
                <Link to='/create' className='btn remove_bg_light btn-block'>
                  <i style={{}} className='la la-file-medical' />
                  <span>Create Rx.</span>
                </Link>
              </div>
              <div className='col-md-4'>
                <Link
                  to='/medicines'
                  className='btn remove_bg_light btn-block'
                >
                  <i style={{}} className='la la-clipboard-list' />
                  <span>Medicines</span>
                </Link>
              </div>
              <div className='col-md-4'>
                <Link
                  to='/patients'
                  className='btn remove_bg_light btn-block'
                >
                  <i style={{}} className='la la-user-circle' />
                  <span>Patients</span>
                </Link>
              </div>
            </div>
            {prescriptions && (
              <h5
                className={`${prescriptions.length ? 'mb-5' : 'mb-0'
                  } font-weight-bold`}
              >
                {!prescriptions.length
                  ? `You haven't created a prescription yet.`
                  : 'Recently Created'}
              </h5>
            )}
            {isLoaging ? (
              <div className='row lists'>
                <HomeMyRx />
                <HomeMyRx />
                <HomeMyRx />
              </div>
            ) : (
              <div className='row lists'>
                {prescriptions &&
                  prescriptions.slice(0, visible).map((prescription) => (
                    <div className='col-12' key={prescription.id}>
                      <Link to={`/prescription/${prescription.id}`}>
                        <div className='card card-custom card-stretch gutter-b shadow'>
                          <div className='card-body p-6'>
                            <div className='d-flex flex-wrap align-items-center'>
                              <div className='icons mr-4'>
                                <i style={{color: '#54b9a8', backgroundColor: '#54b9a820'}} className='la la-user-edit' />
                              </div>
                              <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                                <span className='text-dark-75 font-weight-bolder font-size-lg'>
                                  {prescription.patient ? prescription.patient.name : ''}
                                </span>
                                <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                                  Dx. {prescription.dx}
                                </span>
                                <span className='text-muted font-weight-bold font-size-sm'>
                                  Created at: &nbsp;
                                  <span className='text-dark font-weight-bold'>
                                    <Moment format='DD MMM YYYY, hh:mm A'>
                                      {prescription.created_at}
                                    </Moment>
                                  </span>
                                </span>
                              </div>
                              <div className='d-flex align-items-center py-lg-0 py-2'>
                                <div className='d-flex flex-column text-right'>
                                  <span className='text-dark-75 font-weight-bolder font-size-h4'>
                                    #{prescription.token}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                {showSeeMore && (
                  <div className='col-12'>
                    <p className='text-center mb-0'>
                      <span
                        className='font-weight-bolder font-size-lg pointer text-primary'
                        onClick={handleLoadMore}
                      >
                        See More
                      </span>
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Home;
