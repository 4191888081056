// import AddOne from '../assets/img/one.jpeg';
// import AddTwo from '../assets/img/two.jpeg';
// import AddThree from '../assets/img/three.jpeg';
// import AddFour from '../assets/img/four.jpeg';
import Placeholder from '../assets/img/placeoholder_sqr.jpeg';

const Sidebar = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <div className=' mb-7' >
          <img src={Placeholder} className='img-fluid w-100' alt='Advertisement' />
        </div>
        <div className=' mb-7' >
          <img src={Placeholder} className='img-fluid w-100' alt='Advertisement' />
        </div>
        <div className=' mb-7' >
          <img src={Placeholder} className='img-fluid w-100' alt='Advertisement' />
        </div>
        <div className=' mb-7' >
          <img src={Placeholder} className='img-fluid w-100' alt='Advertisement' />
        </div>
        <div className=' mb-7' >
          <img src={Placeholder} className='img-fluid w-100' alt='Advertisement' />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
