import MedLogo from '../assets/img/medicine.png';
import InjLogo from '../assets/img/syringe.png';

const MedicineItem = ({ medicine }) => {
  return (
    <div className='col-12'>
      <div
        className={`card card-custom card-stretch gutter-b ${
          medicine.form === 'Tablet' ? 'medicine_bg' : ''
        }`}
      >
        <div className='card-body p-6'>
          <div className='d-flex flex-wrap align-items-center medicine'>
            <div className='icons mr-6'>
              <img
                src={medicine.form === 'Injection' ? InjLogo : MedLogo}
                alt='medicine logo'
              />
            </div>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-50 font-size-lg mb-1'>
                <span className='mr-3 text-dark-75 font-weight-bolder'>
                  {medicine.name}
                </span>{' '}
                {medicine.form}
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                {medicine.generic}
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                {medicine.strength ? medicine.strength : 'Not Available'}
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                {medicine.company}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineItem;
