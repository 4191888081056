import Skeleton from 'react-loading-skeleton';

const MedicineLoading = () => {
  return (
    <div className='col-12 homapage-loading'>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card-body p-6'>
          <div className='d-flex flex-wrap align-items-center medicine'>
            <div className='icons ml-2 mr-7'>
              <Skeleton width={60} height={60} />
            </div>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-50 font-size-lg mb-1'>
                <span className='mr-3 text-dark-75 font-weight-bolder'>
                  <Skeleton width={110} />
                </span>{' '}
                <Skeleton width={70} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                <Skeleton width={160} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                <Skeleton width={120} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm my-1'>
                <Skeleton width={220} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineLoading;
