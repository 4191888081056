import Skeleton from 'react-loading-skeleton';

const PatientId = () => {
  return (
    <div className='card-body'>
      <div className='row mb-8'>
        <div className='col-md-12'>
          <h2 className='mb-4'>
            <Skeleton width={210} />
          </h2>
          <div className='card' style={{ background: '#f7f7f7' }}>
            <div className='card-body'>
              <div className='d-flex flex-wrap align-items-start'>
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                  <span className='text-dark-65 font-size-lg'>
                    <Skeleton width={170} />
                  </span>
                  <span className='text-dark-65 font-size-lg mt-2'>
                    <Skeleton width={60} />
                  </span>
                  <span className='text-dark-65 font-size-lg mt-2'>
                    <Skeleton width={70} />
                  </span>
                  <span className='text-dark-65 font-size-lg mt-2'>
                    <Skeleton width={300} />
                  </span>
                  <span className='text-dark-65 font-size-lg mt-2'>
                    <Skeleton width={175} />
                  </span>
                  <span className='text-dark-65 font-size-lg mt-2'>
                    <Skeleton width={155} />
                  </span>
                </div>
                <div className='symbol symbol-60 symbol-2by3 flex-shrink-0'>
                  <span className='icons'>
                    <Skeleton width={35} height={35} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 className='my-4' style={{ fontSize: '18px' }}>
        <Skeleton width={135} />
      </h5>
      <div className='row lists'>
        <div className='col-12'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body p-6'>
              <div className='d-flex flex-wrap align-items-center'>
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                  <span className='font-weight-bolder font-size-lg text-primary'>
                    <Skeleton width={65} />
                  </span>
                  <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={125} />
                  </span>
                  <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={225} />
                  </span>
                  <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={175} />
                  </span>
                  <span className='icons mt-3'>
                    <span className='mr-2'>
                      <Skeleton width={35} height={35} />
                    </span>
                    <span>
                      <Skeleton width={35} height={35} />
                    </span>
                  </span>
                </div>
                <div className='symbol symbol-60 symbol-2by3 flex-shrink-0 shadow'>
                  <Skeleton width={100} height={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <div className='card card-custom card-stretch gutter-b'>
            <div className='card-body p-6'>
              <div className='d-flex flex-wrap align-items-center'>
                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
                  <span className='font-weight-bolder font-size-lg text-primary'>
                    <Skeleton width={65} />
                  </span>
                  <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={125} />
                  </span>
                  <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={225} />
                  </span>
                  <span className='text-dark-65 font-weight-bold font-size-sm mt-2'>
                    <Skeleton width={175} />
                  </span>
                  <span className='icons mt-3'>
                    <span className='mr-2'>
                      <Skeleton width={35} height={35} />
                    </span>
                    <span>
                      <Skeleton width={35} height={35} />
                    </span>
                  </span>
                </div>
                <div className='symbol symbol-60 symbol-2by3 flex-shrink-0 shadow'>
                  <Skeleton width={100} height={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientId;
