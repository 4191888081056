import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Menu from './components/Menu';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import ForgotPassRetrieve from './pages/ForgotPassRetrieve';
import PrescriptionPreview from './components/PrescriptionPreview/PrescriptionPreview';
import PrescriptionPreviewPartner from './components/PrescriptionPreview/PrescriptionPreviewPartner';
import Signup from './pages/Signup';
import LandingPage from './pages/LandingPage';

const App = () => {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            {/* <Route exact path='/home' component={LandingPage} /> */}
            <Route path='/signup' component={Signup} />
            <Route path='/login' component={Login} />
            <Route path='/retieve' component={ForgotPassRetrieve} />
            <Route path='/prescription/:prescription_id' component={PrescriptionPreview} />
            <Route path='/prescription-partner/:prescription_id' component={PrescriptionPreviewPartner} />
            <Route path='/' component={Menu} />
            {/* <Menu /> */}
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
