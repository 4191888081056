import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from './context/AuthContext';

const useFetch = (url) => {
  const { logout } = useAuth();
  const [data, setData] = useState(null);
  const [isLoaging, setIsLoaging] = useState(true);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(5);
  const [showSeeMore, setShowSeeMore] = useState(true);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        // console.log(response.data);
        setData(response.data);
        setIsLoaging(false);
        if (response.data.length < 6) {
          setShowSeeMore(false);
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response && error.response.status === 401) {
          logout();
        }
        setError(error);
        // setIsLoaging(false);
        setShowSeeMore(false);
        toast.error('Sorry, unable to fetch data. Please try again later.', {
          autoClose: 3000,
        });
      });
  }, [url]);

  const handleLoadMore = () => {
    setVisible(visible + 5);
    const postsLength = data.length;
    const nowShowing = visible + 5;
    if (postsLength <= nowShowing) {
      setShowSeeMore(false);
    }
  };

  return { data, isLoaging, error, showSeeMore, handleLoadMore, visible };
};

export default useFetch;
