// import { BrowserRouter as Router } from 'react-router-dom';
import React, { useEffect } from 'react'
import Footer from './Footer';
import Links from './sub/Links';
import Home from '../pages/Home';
import Create from '../pages/Create';
import MyRx from '../pages/MyRx';
import Search from '../pages/Search';
import Statistics from '../pages/Statistics';
import Settings from '../pages/Settings';
import Profile from '../pages/Profile';
import ResetPassword from './ResetPassword/ResetPassword';
// import Logo from '../assets/media/logos/logo-letter-13.png';
import Logo from '../assets/img/logos/E-Laj_Clrd2.png';
import PrivateRoute from './PrivateRoute';
import Medicines from '../pages/Medicines';
import Patients from '../pages/Patients';
import SinglePatients from '../pages/SinglePatient';
// import PrescriptionPreview from '../pages/PrescriptionPreview';
import PatientEdit from '../pages/PatientEdit';

import { useAuth } from '../context/AuthContext';
import {useHistory } from 'react-router-dom';


const Menu = () => {
  const { currentUser } = useAuth();
  const history = useHistory();
  
  // useEffect(() => {
  //   if (!localStorage.getItem('user-info') || !currentUser) {
  //     history.push('/home');
  //   }
  // }, []);
  
  return (
    <>
      <div
        id='kt_body'
        className='header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed page-loading'
      >
        <div
          id='kt_header_mobile'
          className='header-mobile align-items-center header-mobile-fixed'
        >
          <a href='index.html'>
            <img alt='Logo' className='w-45px' src={Logo} />
          </a>
          <div className='d-flex align-items-center'>
            <button
              className='btn p-0 burger-icon ml-4'
              id='kt_aside_mobile_toggle'
            >
              <span></span>
            </button>
          </div>
        </div>
        <div className='d-flex flex-column flex-root'>
          <div className='d-flex flex-row flex-column-fluid page'>
            <Links />
            <div
              className='d-flex flex-column flex-row-fluid wrapper pt-0'
              id='kt_wrapper'
            >
              <div
                className='content d-flex flex-column flex-column-fluid'
                id='kt_content'
                style={{ minHeight: '93vh' }}
              >
                <div className='d-flex flex-column-fluid'>
                  <div className='container'>
                    <PrivateRoute exact path='/' component={Home} />
                    <PrivateRoute path='/create' component={Create} />
                    <PrivateRoute path='/my-prescriptions' component={MyRx} />
                    <PrivateRoute path='/search' component={Search} />
                    <PrivateRoute path='/statistics' component={Statistics} />
                    <PrivateRoute path='/profile' component={Profile} />
                    <PrivateRoute path='/resetpass' component={ResetPassword} />
                    <PrivateRoute path='/settings' component={Settings} />
                    <PrivateRoute path='/medicines' component={Medicines} />
                    <PrivateRoute
                      exact
                      path='/patient/:patient_id'
                      component={SinglePatients}
                    />
                    <PrivateRoute
                      exact
                      path='/patient/:patient_id/edit'
                      component={PatientEdit}
                    />
                    <PrivateRoute path='/patients' component={Patients} />
                    {/* <PrivateRoute path='/prescription/:prescription_id' component={PrescriptionPreview}/> */}
                  </div>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
