import React, { useState, useEffect } from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, LinkedinShareButton, FacebookMessengerShareButton } from 'react-share';
import { FaFacebookF, FaTwitter, FaWhatsapp, FaLinkedinIn, FaFacebookMessenger } from "react-icons/fa";
import { GrMail } from "react-icons/gr";

import './SocialShareModalStyle.css';

export const SocialShareModal = ({
    modalVisible,
    setModalVisible,
}) => {

    const [InitialView, setInitialView] = useState(true);
    const shareUrl = window.location.href;
    const title = 'View your prescription now.'

    useEffect(() => {

    }, []);

    const handleCloseModal = () => {
        setInitialView(false);
        setModalVisible(false);
    }

    return (
        <div className={modalVisible ? 'modal-container modal-container-view' : InitialView ? 'modal-container hidden' : 'modal-container modal-container-out'} /*onClick={() => handleCloseModal()}*/>
            <div className={modalVisible ? 'modal-wrapper modal-wrapper-view' : InitialView ? 'modal-wrapper hidden' : 'modal-wrapper modal-wrapper-out'}>
                <div className="modal-title-wrapper">
                    <div className="modal-title">
                        Share Prescription
                    </div>
                </div>
                <div className='content-wrapper'>
                    <FacebookShareButton 
                    url={shareUrl}
                    quote={title}>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#3b5998', borderColor: '#3b5998' }} >
                            <FaFacebookF size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">Facebook</div>
                        </div>
                    </FacebookShareButton>
                    <TwitterShareButton
                    url={shareUrl}
                    title={title}>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#27a1f2', borderColor: '#27a1f2' }} >
                            <FaTwitter size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">Twitter</div>
                        </div>
                    </TwitterShareButton>
                    <WhatsappShareButton
                    url={shareUrl}
                    title={title}
                    separator=':: '>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#64c248', borderColor: '#64c248' }} >
                            <FaWhatsapp size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">Whatsapp</div>
                        </div>
                    </WhatsappShareButton>
                    <LinkedinShareButton
                    url={shareUrl}
                    title={title}>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#1a77b5', borderColor: '#1a77b5' }} >
                            <FaLinkedinIn size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">LinkedIn</div>
                        </div>
                    </LinkedinShareButton>
                    <FacebookMessengerShareButton
                    url={shareUrl}
                    title={title}>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#2096f3', borderColor: '#2096f3' }} >
                            <FaFacebookMessenger size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">Messenger</div>
                        </div>
                    </FacebookMessengerShareButton>
                    <EmailShareButton
                    url={shareUrl}
                    title={title}
                    body='body'>
                        <div className="share-btn-wrapper" style={{ color: '#fff', backgroundColor: '#db4437', borderColor: '#db4437' }} >
                            <GrMail size={17} style={{ height: '15px' }} />
                            <div className="share-btn-txt">Gmail</div>
                        </div>
                    </EmailShareButton>
                </div>
                <div className='button-wrapper'>
                    <div className='close-btn' onClick={() => handleCloseModal()}>Close</div>
                    {/* <div className="done-btn">Done</div> */}
                </div>
            </div>
        </div>
    )
}

export default SocialShareModal
