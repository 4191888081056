import Skeleton from 'react-loading-skeleton';

const PresMyRx = () => {
  return (
    <div className='col-12'>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card-body p-6'>
          <div className='d-flex flex-wrap align-items-center'>
            <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3'>
              <span className='text-dark-75 font-weight-bolder font-size-lg'>
                <Skeleton width={160} />
              </span>
              <span className='text-dark-50 font-weight-bold font-size-sm mt-2'>
                <Skeleton width={200} />
              </span>
              <span className='icons mt-3'>
                <span className='mr-2'>
                  <Skeleton width={35} height={35} />
                </span>
                <Skeleton width={35} height={35} />
              </span>
            </div>
            <div
              className='symbol symbol-60 symbol-2by3 flex-shrink-0'
              style={{ position: 'relative' }}
            >
              <Skeleton width={90} height={90} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresMyRx;
