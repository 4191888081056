// import Logo from '../assets/media/logos/logo-letter-13.png'
import Logo from '../assets/img/logos/E-Laj_Clrd2.png';
import '../assets/css/pages/login/classic/login-5.css'
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';
import { specialities } from '../assets/Utils/specialityList/specialities';

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [Speciality, setSpeciality] = useState('');
  const [BMDC, setBMDC] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [mobile, setMobile] = useState('');
  const history = useHistory();
  const { register } = useAuth();
  const [tAcAgree, setTaCagree] = useState(false);

  const handleCheckbox = () => {
    setTaCagree(!tAcAgree);
  }

  useEffect(() => {
    if (localStorage.getItem('user-info')) {
      history.push('/');
    }
  }, [])

  const handleSignup = (e) => {
    e.preventDefault();

    if (!tAcAgree) {
      alert('Please accept the Terms of Service and Privecy Policy.')
      return;
    }

    if (fullName === '') {
      toast.error('Please enter your name!', {
        autoClose: 2000,
      });
    } else if (name === '') {
      toast.error('Please enter username!', {
        autoClose: 2000,
      });
    }
    // else if (mobile === '') {
    //   toast.error('Please enter your mobile number!', {
    //     autoClose: 2000,
    //   });
    // }  
    else if (Speciality === '') {
      toast.error('Please enter speciality!', {
        autoClose: 2000,
      });
    }
    // else if(email === '') {
    //   toast.error('Please enter email!', {
    //     autoClose: 2000,
    //   });
    // } 
    else if (password === '') {
      toast.error('Please enter password!', {
        autoClose: 2000,
      });
    }
    // else if(confirmPassword === '') {
    //   toast.error('Please enter confirm password!', {
    //     autoClose: 2000,
    //   });
    // } 
    // else if(password !== confirmPassword) {
    //   toast.error(`Password and Confirm password don't matched!`, {
    //     autoClose: 2000,
    //   });
    // } 
    else {
      console.log('come reg');
      register(name, email, password, confirmPassword, fullName, mobile, Speciality, BMDC);
    }
  }

  return (
    <div className="login_signup">
      <div id="kt_body" className="header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed page-loading">
        <div className="d-flex flex-column flex-root">
          <div className="login login-5 login-signin-on d-flex flex-row-fluid" id="kt_login">
            <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
              <div className="login-form text-center text-white p-7 position-relative overflow-hidden">
                <div className="d-flex flex-center mb-15">
                  <Link to='/'>
                    <img src={Logo} className="max-h-120px" alt="" />
                  </Link>
                </div>
                <div className="login-signin">
                  <div className="mb-10" style={{ marginTop: '-20px' }}>
                    <h3 className="opacity-40 font-weight-normal">Sign Up</h3>
                    <p className="opacity-40">Enter your details to create your account</p>
                  </div>
                  <form className="form" onSubmit={handleSignup}>
                    <div className="form-group">
                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="text" placeholder="enter your name" onChange={(e) => setFullName(e.target.value)} value={fullName} />
                    </div>
                    {/* <div className="form-group">
                                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="email" placeholder="enter email" onChange={(e)=>setEmail(e.target.value)} value={email} />
                                  </div> */}
                    <div className="form-group">
                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="number" placeholder="enter mobile number" onChange={(e) => setMobile(e.target.value)} value={mobile} />
                    </div>
                    <div className="form-group">
                      <select
                        className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8 mr-8 speciality meal_select"
                        onChange={(e) => setSpeciality(e.target.value)}
                        value={Speciality}
                      >
                        <option disabled={true} style={{color: '#000'}} value=''>Speciality</option>
                        {specialities.map(item=> item.label).sort().map((speciality, index)=> <option key={index} style={{color: '#000'}} value={speciality}>{speciality}</option>)}
                      </select>
                      {/* <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="text" placeholder="enter speciality" onChange={(e)=>setSpeciality(e.target.value)} value={Speciality} /> */}
                    </div>
                    <div className="form-group">
                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="text" placeholder="enter BMDC number" onChange={(e) => setBMDC(e.target.value)} value={BMDC} />
                    </div>
                    <div className="form-group">
                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="text" placeholder="enter username" onChange={(e) => setName(e.target.value)} value={name} />
                    </div>
                    <div className="form-group">
                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="password" placeholder="enter password" onChange={(e) => setPassword(e.target.value)} value={password} />
                    </div>
                    {/* <div className="form-group">
                                      <input className="form-control h-auto text-white bg-white-o-5 border-0 py-4 px-8" type="password" placeholder="confirm password" onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword} />
                                  </div> */}
                    <div className="form-group text-left px-8">
                      <div className="checkbox-inline">
                        <label className="checkbox checkbox-outline checkbox-white opacity-60 text-white m-0">
                          <input type="checkbox" name="agree" onClick={handleCheckbox} />
                          <span></span>I Agree to the
                          <Link to='/terms-and-conditions' className="text-white font-weight-bold ml-1">Terms of Service and Privecy Policy</Link>.
                        </label>
                      </div>
                      <div className="form-text text-muted text-center"></div>
                    </div>
                    <div className="form-group text-center mt-10">
                      <button disabled={!tAcAgree} id="kt_login_signin_submit" className="btn btn-pill btn-primary opacity-90 px-15 py-3">Sign Up</button>
                    </div>
                  </form>
                  <div className="mt-10 mb-5">
                    <span className="opacity-40 mr-4">Already have an account?</span>
                    <Link to='/login' id="kt_login_signup" className="text-white opacity-30 font-weight-normal">Sign In</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
