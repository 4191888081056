import useFetch from '../useFetch';
import Skeleton from 'react-loading-skeleton';
import PatientLoading from './Loading/PatientLoading';
import SignlePatient from './SignlePatient';

const Patient = ({
  showSearch,
  searchResult,
  searchSeeMore,
  searchVisible,
  handleSearchLoadMore,
}) => {
  const {
    data: patients,
    showSeeMore,
    handleLoadMore,
    visible,
    isLoaging,
  } = useFetch(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/`);

  return (
    <div>
      {isLoaging ? (
        <div>
          <h5 className='mt-8 mb-4' style={{ fontSize: '18px' }}>
            <Skeleton width={175} />
          </h5>
          <PatientLoading />
          <PatientLoading />
          <PatientLoading />
        </div>
      ) : (
        <div>
          {!showSearch ? (
            <div>
              {patients && (
                <div>
                  <h5 className='my-4' style={{ fontSize: '18px' }}>
                    {patients.length} Patients Found
                  </h5>
                  <div className='row lists'>
                    {patients.slice(0, visible).map((patient, i) => (
                      <SignlePatient patient={patient} key={i} />
                    ))}
                    {showSeeMore && (
                      <div className='col-12'>
                        <p className='text-center mb-0'>
                          <span
                            className='font-weight-bolder font-size-lg pointer text-primary'
                            onClick={handleLoadMore}
                          >
                            See More
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className='row lists'>
                {!searchResult.length && (
                  <div className='col-md-12'>
                    <h5 className='font-weight-bold'>
                      Sorry, no patient found.
                    </h5>
                  </div>
                )}
                {searchResult.slice(0, searchVisible).map((patient, i) => (
                  <SignlePatient patient={patient} key={i} />
                ))}
                {searchSeeMore && (
                  <div className='col-12'>
                    <p className='text-center mb-0'>
                      <span
                        className='font-weight-bolder font-size-lg pointer text-primary'
                        onClick={handleSearchLoadMore}
                      >
                        See More
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Patient;
