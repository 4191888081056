import Sidebar from '../components/Sidebar';
import { useState, useEffect } from 'react';
import Prescription from '../components/Prescription';
import Medicine from '../components/Medicine';
import Patient from '../components/Patient';
import useFetch from '../useFetch';

const Search = () => {
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchVisible, setSearchVisible] = useState(5);
  const [searchSeeMore, setSearchSeeMore] = useState(true);
  const [next, setNext] = useState('');

  const [tabName, setTabName] = useState('prescription');
  const [placeholder, setPlaceholder] = useState(
    'Search Rx. (Name, ID or Mobile Number)'
  );

  const [medicines, setMedicines] = useState([]);
  let medicinesArr = [];
  const [count, setCount] = useState(0);
  const [medNext, setMedNext] = useState('');
  let isMounted = true;
  let isFocused = false;


  const prescriptionTab = () => {
    setTabName('prescription');
    setPlaceholder('Search Rx. (Name, ID or Mobile Number)');
    setSearchText('');
    setShowSearch(false);
    setSearchVisible(5);
    setNext('');
  };

  const medicineTab = () => {
    setTabName('medicine');
    setPlaceholder('Search Medicines (Name, Gener etc)');
    setSearchText('');
    setShowSearch(false);
    setSearchVisible(5);
    setNext('');
  };

  const patientTab = () => {
    setTabName('patient');
    setPlaceholder('Search Patients (Name, ID or Mobile Number)');
    setSearchText('');
    setShowSearch(false);
    setSearchVisible(5);
    setNext('');
  };

  const fetchMedicine = async (url) => {
    // console.log('url: ', url);
    let responsePromise = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    
    let response = await responsePromise.json();
    if (isMounted) {
      medicinesArr = [...medicinesArr, ...response.results];
      setMedicines(medicinesArr);
      setCount(response.count);
      setMedNext(response.next);
      if (response.next) await fetchMedicine(response.next);
    }

    // console.log(response)
  }

  useEffect(() => {
    fetchMedicine(`${process.env.REACT_APP_AMARLAB_API}/medicine/store-get/?limit=1000`)

    return (() => {
      isMounted = false;
    })

  }, [isFocused])

  // const { data: medicines } = useFetch(
  //   `${process.env.REACT_APP_AXIOS_BASE_URL}/api/medicine_limit/1000`
  // );

  const { data: patients } = useFetch(
    `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/`
  );

  const { data: prescriptions } = useFetch(
    `${process.env.REACT_APP_AXIOS_BASE_URL}/api/prescription/`
  );

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    console.log('medicine: ', medicines);
    if (e.target.value.length) {
      setShowSearch(true);
      if (tabName === 'prescription') {
        const filterRx = prescriptions.filter((rx) => {
          return (
            rx.patient && (rx.patient.mobile
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            rx.patient.name.toLowerCase().includes(e.target.value.toLowerCase()))
          );
        });
        if (filterRx.length < 6) {
          setSearchSeeMore(false);
        } else {
          setSearchSeeMore(true);
        }
        // console.log(filterRx);
        setSearchResult(filterRx);
      } else if (tabName === 'medicine') {
        console.log(medicines, e.target.value)
        const filterMedicine = medicines.filter((medicine) => {
          return (
            medicine.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
            // ||
            // medicine.genric_name
            //   .toLowerCase()
            //   .includes(e.target.value.toLowerCase())
          );
        });
        if (filterMedicine.length < 6 && !next) {
          setSearchSeeMore(false);
        } else {
          setSearchSeeMore(true);
        }
        // console.log(filterMedicine);
        setSearchResult(filterMedicine);
      } else if (tabName === 'patient') {
        const filterPatients = patients.filter((patient) => {
          return (
            patient.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            patient.mobile.toLowerCase().includes(e.target.value.toLowerCase())
          );
        });
        if (filterPatients.length < 6 && !next) {
          setSearchSeeMore(false);
        } else {
          setSearchSeeMore(true);
        }
        // console.log(filterPatients);
        setSearchResult(filterPatients);
      }
    } else {
      setShowSearch(false);
      setSearchVisible(5);
    }
  };

  const handleSearchLoadMore = () => {
    setSearchVisible(searchVisible + 5);
    const searchLength = searchResult.length;
    const nowShownig = searchVisible + 5;
    if (searchLength <= nowShownig) {
      setSearchSeeMore(false);
    }
  };

  return (
    <div className='row myrx search'>
      <div className='col-md-8'>
        <div className='card'>
          <div className='card-body'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder={placeholder}
              value={searchText}
              onChange={handleSearch}
            />
            <div className='row mt-4 mb-8'>
              <div className='col-md-4'>
                <button
                  className={`btn btn-light-primary btn-block btn-lg tab ${tabName === 'prescription' ? 'active' : ''
                    }`}
                  onClick={prescriptionTab}
                >
                  Prescription
                </button>
              </div>
              <div className='col-md-4'>
                <button
                  className={`btn btn-light-primary btn-block btn-lg tab ${tabName === 'medicine' ? 'active' : ''
                    }`}
                  onClick={medicineTab}
                >
                  Medicine
                </button>
              </div>
              <div className='col-md-4'>
                <button
                  className={`btn btn-light-primary btn-block btn-lg tab ${tabName === 'patient' ? 'active' : ''
                    }`}
                  onClick={patientTab}
                >
                  Patient
                </button>
              </div>
            </div>
            {tabName === 'prescription' && (
              <Prescription
                showSearch={showSearch}
                searchResult={searchResult}
                searchSeeMore={searchSeeMore}
                searchVisible={searchVisible}
                handleSearchLoadMore={handleSearchLoadMore}
              />
            )}
            {tabName === 'medicine' && (
              <Medicine
                showSearch={showSearch}
                searchResult={searchResult}
                setSearchResult={setSearchResult}
                searchSeeMore={searchSeeMore}
                setSearchSeeMore={setSearchSeeMore}
                searchVisible={searchVisible}
                setSearchVisible={setSearchVisible}
                setNextP={setNext}
                handleSearchLoadMore={handleSearchLoadMore}
              />
            )}
            {tabName === 'patient' && (
              <Patient
                showSearch={showSearch}
                searchResult={searchResult}
                searchSeeMore={searchSeeMore}
                searchVisible={searchVisible}
                handleSearchLoadMore={handleSearchLoadMore}
              />
            )}
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default Search;
