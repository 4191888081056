import Sidebar from '../components/Sidebar';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import PatientDobEdit from '../components/PatientDobEdit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { thanasByDistrict } from '../assets/Utils/geodata/thanasByDistrict';
import { occupations } from '../assets/Utils/occupationList/occupationList';

const distList = Object.keys(thanasByDistrict).sort()

const PatientEdit = () => {
  const history = useHistory();
  const [patient, setPatient] = useState();
  const [patientName, setPatientName] = useState('');
  // const [patientDob, setPatientDob] = useState('');
  const [PatientAge, setPatientAge] = useState('');
  const [PatientAgeType, setPatientAgeType] = useState('');
  const [patientGender, setPatientGender] = useState('');
  const [patientMaritalStatus, setPatientMaritalStatus] = useState('');
  const [patientOccupation, setPatientOccupation] = useState('');
  const [patientDistrict, setPatientDistrict] = useState('');
  const [patientThana, setPatientThana] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [allDistrict] = useState([
    'Bagerhat',
    'Bandarban',
    'Barguna',
    'Barisal',
    'Bhola',
    'Bogra',
    'Brahmanbaria',
    'Chandpur',
    'Chapainawabganj',
    'Chittagong',
    'Chuadanga',
    'Comilla',
    "Cox's Bazar",
    'Dhaka',
    'Dinajpur',
    'Faridpur',
    'Feni',
    'Gaibandha',
    'Gazipur',
    'Gopalganj',
    'Habiganj',
    'Jamalpur',
    'Jessore',
    'Jhalokati',
    'Jhenaidah',
    'Joypurhat',
    'Khagrachhari',
    'Khulna',
    'Kishoreganj',
    'Kurigram',
    'Kushtia',
    'Lakshmipur',
    'Lalmonirhat',
    'Madaripur',
    'Magura',
    'Manikganj',
    'Meherpur',
    'Moulvibazar',
    'Munshiganj',
    'Mymensingh',
    'Naogaon',
    'Narail',
    'Narayanganj',
    'Narsingdi',
    'Natore',
    'Netrakona',
    'Nilphamari',
    'Noakhali',
    'Pabna',
    'Panchagarh',
    'Patuakhali',
    'Pirojpur',
    'Rajbari',
    'Rajshahi',
    'Rangamati',
    'Rangpur',
    'Satkhira',
    'Shariatpur',
    'Sherpur',
    'Sirajganj',
    'Sunamganj',
    'Sylhet',
    'Tangail',
    'Thakurgaon',
  ]);

  const { patient_id } = useParams();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/${patient_id}`)
      .then((resp) => {
        // console.log(resp.data);
        setIsLoading(false);
        setPatient(resp.data);
        setPatientName(resp.data.name);
        // setPatientDob(resp.data.dob);
        setPatientAge(resp.data.age);
        setPatientAgeType(resp.data.age_type);
        setPatientGender(resp.data.gender);
        setPatientMaritalStatus(resp.data.marital_status);
        setPatientOccupation(resp.data.occupation);
        setPatientDistrict(resp.data.address_district);
        setPatientThana(resp.data.address_thana);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Sorry! something went wrong. Please try again later.', {
          autoClose: 3000,
        });
        setIsLoading(false);
        history.push('/');
      });
  }, []);

  const idGen = (id) => {
    if (id < 10) {
      return '0000' + id;
    } else if (id < 100) {
      return '000' + id;
    } else if (id < 1000) {
      return '00' + id;
    } else if (id < 10000) {
      return '0' + id;
    } else {
      return id;
    }
  };

  const handlePatient = (e) => {
    e.preventDefault();

    if (patientName === '') {
      toast.error('Please enter patient name.', {
        autoClose: 3000,
      });
    }
    // else if (patientDob === '' || patientDob === null) {
    //   toast.error('Please enter patient age.', {
    //     autoClose: 3000,
    //   });
    // } 
    else if (PatientAge === '' || PatientAge === null) {
      toast.error('Please enter patient age.', {
        autoClose: 3000,
      });
    }
    else if (PatientAgeType === '' || PatientAgeType === null) {
      toast.error('Please enter patient age type.', {
        autoClose: 3000,
      });
    }
    else if (patientGender === '') {
      toast.error('Please enter patient gender.', {
        autoClose: 3000,
      });
    } else if (patientMaritalStatus === '') {
      toast.error('Please enter patient marital status.', {
        autoClose: 3000,
      });
    } else if (patientOccupation === '') {
      toast.error('Please enter patient occupation.', {
        autoClose: 3000,
      });
    } else if (patientThana === '') {
      toast.error('Please enter patient address.', {
        autoClose: 3000,
      });
    } else if (patientDistrict === '') {
      toast.error('Please enter patient district.', {
        autoClose: 3000,
      });
    } else {
      setIsUpdating(true);
      const postPatient = {
        name: patientName,
        // dob: moment(patientDob).format('YYYY-MM-DD'),
        age: parseInt(PatientAge),
        age_type: PatientAgeType.slice(0, -1),
        gender: patientGender,
        marital_status: patientMaritalStatus,
        occupation: patientOccupation,
        address_district: patientDistrict,
        address_thana: patientThana,
      };
      axios
        .put(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/api/patient/${patient.id}/`,
          postPatient
        )
        .then((resp) => {
          console.log(resp.data);
          setIsUpdating(false);
          toast.success('Patient details updated successfully.', {
            autoClose: 3000,
          });
          history.push(`/patient/${resp.data.id}`);
        })
        .catch((error) => {
          console.log(error);
          toast.error('Sorry! something went wrong. Please try again later.', {
            autoClose: 3000,
          });
          setIsUpdating(false);
          history.push(`/patient/${patient.id}`);
        });
    }
  };

  const handleDistrictSelection = (value) => {
    setPatientDistrict(value);
    setPatientThana('');
  }

  return (
    <div className='row edit_patient'>
      <div className='col-md-8'>
        <div className='card'>
          {!isLoading ? (
            <div className='card-body'>
              {patient && (
                <div>
                  <h2 className='mb-4'>
                    Edit Patient: #{idGen(patient.token)}
                  </h2>
                  <form onSubmit={handlePatient}>
                    <div className='form-group'>
                      <input
                        type='text'
                        className='form-control form-control-lg'
                        placeholder='Patient Name'
                        value={patientName}
                        onChange={(e) => setPatientName(e.target.value)}
                      />
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group' style={{ display: 'flex' }}>
                          {/* <PatientDobEdit
                            date={patientDob}
                            setDate={setPatientDob}
                          /> */}
                          <input
                            type='text'
                            className='form-control form-control-lg'
                            placeholder='Age'
                            value={PatientAge ? PatientAge : ''}
                            onChange={(e) => setPatientAge(e.target.value)}
                            disabled={patient.age}
                          />
                          <select
                            className='form-control form-control-lg meal_select'
                            disabled={patient.age_type}
                            onChange={(e) => setPatientAgeType(e.target.value)}
                            value={PatientAgeType ? PatientAgeType : 'years'}
                          >
                            <option value='days'>Days</option>
                            <option value='months'>Months</option>
                            <option value='years'>Years</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) => setPatientGender(e.target.value)}
                            value={patientGender ? patientGender : ''}
                          >
                            <option value=''>Gender</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Common'>Common</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) =>
                              setPatientMaritalStatus(e.target.value)
                            }
                            value={
                              patientMaritalStatus ? patientMaritalStatus : ''
                            }
                          >
                            <option value=''>Marital Status</option>
                            <option value='Single'>Single</option>
                            <option value='Married'>Married</option>
                            <option value='Widowed'>Widowed</option>
                            <option value='Divorced'>Divorced</option>
                            <option value='Separated'>Separated</option>
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          {/* <input
                            type='text'
                            className='form-control form-control-lg'
                            placeholder='Patient Occupation'
                            value={patientOccupation}
                            onChange={(e) =>
                              setPatientOccupation(e.target.value)
                            }
                          /> */}
                          <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) => setPatientOccupation(e.target.value)}
                            value={patientOccupation}
                          >
                            <option value=''>Occupation</option>
                            {occupations &&
                              occupations.map((occupation, index) => (
                                <option value={occupation} key={index}>
                                  {occupation}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          {/* <input
                            type='text'
                            className='form-control form-control-lg'
                            placeholder='Enter address'
                            value={patientThana}
                            onChange={(e) => setPatientThana(e.target.value)}
                          /> */}
                          <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) => setPatientThana(e.target.value)}
                            value={patientThana}
                          >
                            <option disabled={true} value=''>Thana</option>
                            {thanasByDistrict[patientDistrict] &&
                              thanasByDistrict[patientDistrict].map((thana, index) => (
                                <option value={thana} key={index}>
                                  {thana}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          {/* <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) => setPatientDistrict(e.target.value)}
                            value={patientDistrict}
                          >
                            <option value=''>District</option>
                            {allDistrict &&
                              allDistrict.map((district, index) => (
                                <option value={district} key={index}>
                                  {district}
                                </option>
                              ))}
                          </select> */}
                          <select
                            className='form-control form-control-lg meal_select'
                            onChange={(e) => handleDistrictSelection(e.target.value)}
                            value={patientDistrict}
                          >
                            <option disabled={true} value=''>District</option>
                            {distList &&
                              distList.map((district, index) => (
                                <option value={district} key={index}>
                                  {district}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='float-right'>
                      <button
                        type='button'
                        className='btn btn-primary mr-2'
                        onClick={() => {
                          history.go(-1);
                        }}
                      >
                        Go Back
                      </button>
                      {isUpdating ? (
                        <button
                          type='submit'
                          className='btn btn-primary'
                          disabled
                          style={{
                            cursor: 'initial',
                          }}
                        >
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='mr-2'
                            style={{
                              width: '15px',
                              height: '15px',
                            }}
                          />
                          Updating
                        </button>
                      ) : (
                        <button type='submit' className='btn btn-primary'>
                          Update
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          ) : (
            <div className='card-body'>
              <h2 className='mb-4'>
                <Skeleton width={230} />
              </h2>
              <form>
                <div className='form-group'>
                  <Skeleton height={44} />
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <Skeleton height={44} />
                    </div>
                  </div>
                </div>
                <div className='float-right d-flex'>
                  <Skeleton height={44} width={80} className='mr-2' />
                  <Skeleton height={44} width={80} />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className='col-md-4'>
        <Sidebar />
      </div>
    </div>
  );
};

export default PatientEdit;
